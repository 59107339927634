import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Modal, Box, Grid, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assets Import
import CloseIcon from '@mui/icons-material/ClearOutlined';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'background.paper',
    boxShadow: 24,
    borderRadius:"6px",
    gap: "12px",
    zIndex: "999"
};

function AddStaffModal({ open, onClose }) {

    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')


    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Header>
                        <Heading>Add New Staff</Heading>
                        <CloseIcon sx={{cursor: "pointer"}} onClick={onClose} />
                    </Header>
                    <Wrapper>
                        <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                            <Grid item md={4}>
                                <Label>Full Name</Label>
                                <Input value={fullname} onChange={(e) => setFullname(e.target.value)} />
                            </Grid>
                            <Grid item md={4}>
                                <Label>Email</Label>
                                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Grid>
                            <Grid item md={4}>
                                <Label>Phone</Label>
                                <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth size="small" sx={{width: "80%", borderRadius: '5px' }}>
                                    <Label>Select a Role</Label>
                                    <Select>
                                        <MenuItem value={"staff"}>Staff</MenuItem>
                                        <MenuItem value={"manager"}>Manager</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <SaveBTN>Add</SaveBTN>
                            </Grid>
                        </Grid>
                    </Wrapper>
                </Box>
            </Modal>
        </>
    )
}

const Header = styled(Box)(() => ({
    width: "auto",
    height: "auto",
    padding: "15px",
    background: "#dddddd",
    borderRadius: "6px 6px 0 0",
    borderBottom: "!px solid #FFC107",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600"
}))

const Wrapper = styled(Box)(() => ({
    width: "100%",
    height: "auto",
    padding: "32px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px', 
    height: '40px', 
    fontSize: "16px",
    float: "right",
    marginTop: '10px', 
    background: `${theme.palette.primary.main}`, 
    color: "#fff", 
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default AddStaffModal