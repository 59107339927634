import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Typography, TextField } from "@mui/material";

function FormattedInput(props) {

    const { label, size, value, onChange } = props;

    const handleInputChange = (e) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/,/g, '');

        // Allow clearing the input field entirely
        if (inputValue === '' || /^\d{0,10}(\.\d{0,2})?$/.test(inputValue)) {
            // Format the input value with commas
            const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            onChange(formattedValue); // Call the onChange function from props to update the state
        }
    };

    const handleBlur = () => {
        // If the input value is not empty and does not contain a decimal point, add ".00" at the end
        if (value && !/\./.test(value)) {
            onChange(value + ".00");
        }
    };

    return (
        <>
            <Wrapper item md={size} my={2}>
                <Label>{label}</Label>
                <Input mt={3} type="text" size="small" color='success' variant="outlined"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    value={value} onChange={handleInputChange}
                    onBlur={handleBlur}
                />
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Grid)(({
    display: "flex",
    flexDirection: "column"
}))

const Label = styled(Typography)(({
    fontSize: "12px",
    fontWeight: "500"
}))

const Input = styled(TextField)(({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "12px"
}))

export default FormattedInput