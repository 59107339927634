import React, { useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Grid, Modal, Box, Typography, TextField, Button } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Icons Import
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';

// Formik & Yup Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { baseUrl } from '../../../../../constants/api';

const validationSchema = Yup.object({
    engName: Yup.string().required('English Name is required'),
    arabicName: Yup.string().required('Arabic Name is required'),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"8px",
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    gap: "12px"
};

function CategoryModal({ open, setOpen, fetchEntities }) {

    const token = window.localStorage.getItem("user-token");

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };

    const [saving, setSaving] = useState(false)
    const [image, setImage] = useState(null)
    const [previewImage, setPreviewImg] = useState(null);
    
    const handleImageUpload = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(file);
			setPreviewImg(URL.createObjectURL(file));
		}
	};

    const formik = useFormik({
        initialValues: {
            engName: '',
            arabicName: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSave(formik.values);
        },
    });

    const handleSave = (formValues) => {
        if (!image) {
            toast.error("Please upload an image.");
            return;
        }

        const formData = new FormData();

		formData.append("name", formValues.engName);
		formData.append("arb_name", formValues.arabicName);
		if (typeof image !== "string") {
			formData.append("image", image);
		}

        setSaving(true)
        axios.post(`${baseUrl}category/create`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Category created successfully!");
            setTimeout(() => {
                setSaving(false)
            }, 2000);
            formik.resetForm();
            fetchEntities()
            setImage(null)
            setOpen(false)
        })
        .catch((err) => {
            if(err.response) {
                toast.error(err.response.data.message);
            }
            setSaving(false)
        })
    }

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Heading>Add a new Category</Heading>
                    <Input mt={3} placeholder='Category Name English'
                        {...formik.getFieldProps('engName')}
                        error={formik.touched.engName && Boolean(formik.errors.engName)}
                        helperText={formik.touched.engName && formik.errors.engName}
                    />
                    <Input mt={3} placeholder='Category Name Arabic'
                       {...formik.getFieldProps('arabicName')}
                       error={formik.touched.arabicName && Boolean(formik.errors.arabicName)}
                       helperText={formik.touched.arabicName && formik.errors.arabicName}
                    />
                    <Grid container display={"flex"} alignItems={"center"}>
                        <Uploader item md={3} onClick={() => selectFiles()}>
                            <input type="file" accept="image/*" ref={imgUpload} style={{ display: 'none' }} onChange={(e) => handleImageUpload(e)}
                                id="imageInput"
                            />
                            <AddPhotoIcon sx={{ fontSize: '25px' }} />
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>Add Image</Typography>
                        </Uploader>
                        <Grid item md={9}>
                            {image ? (
                                <ImgDisplay sx={{
                                    background: `url(${previewImage}) center/cover no-repeat`,
                                }} />
                            ) : null}
                        </Grid>
                    </Grid>
                    <SaveBTN onClick={() => formik.handleSubmit()}>
                        {saving ? (
                            <>                                    
                                <MoonLoader color="#fff" size={20} />
                            </>
                            ) : (
                            "Save"
                        )}
                    </SaveBTN>
                </Box>
            </Modal>
        </>
    )
}

const Heading = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "600"
}))

const Input = styled(TextField)(({
    background: "#fff",
    borderRadius: "5px"
}))

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '100px',
    height: '90px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    borderRadius: '10px',
}))

const SaveBTN = styled(Button)(() => ({
    width: '150px', 
    height: '40px', 
    margin: '0 auto', 
    background: "#812990", 
    color: "#fff", 
    borderRadius: '5px',
    "&:hover": {
        backgroundColor: "#812990"
    }
}))

export default CategoryModal