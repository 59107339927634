import { apiSlice } from "./apiSlice";

export const variantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVariants: builder.query({
      query: () => {
        return {
          url: `variants`,
          method: "GET",
        };
      },
      providesTags: ["Variants"],
    }),
    createVariant: builder.mutation({
      query: (body) => {
        return {
          url: `variant/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Variants"],
    }),
    updateVariant: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `variant/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Variants"],
    }),
    deleteVariant: builder.mutation({
      query: (id) => {
        return {
          url: `variant/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Variants"],
    }),
  }),
});

export const {
  useGetVariantsQuery,
  useCreateVariantMutation,
  useUpdateVariantMutation,
  useDeleteVariantMutation,
} = variantsApiSlice;
