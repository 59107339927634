import { apiSlice } from "./apiSlice";

export const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: ({ limit, offset, searchTerm, category, serviceType, whole }) => {
        const url = whole
          ? "services"
          : `services?limit=${limit}&offset=${offset}&search_term=${searchTerm}&category_id=${category}&service_type=${serviceType}`;
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Services"],
    }),

    createService: builder.mutation({
      query: (body) => {
        return {
          url: "service/create",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Services"],
    }),

    updateService: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `service/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Services"],
    }),

    getService: builder.query({
      query: (id) => {
        return {
          url: `service/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Services"],
    }),

    deleteService: builder.mutation({
      query: (id) => {
        return {
          url: `service/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Services"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useGetServiceQuery,
  useDeleteServiceMutation,
} = serviceApiSlice;
