import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Typography, TextField } from "@mui/material";

function InputField(props) {

    const { label, size, value, onChange } = props

    return (
        <>
            <Wrapper item md={size} my={2}>
                <Label>{label}</Label>
                <Input mt={3} type="text" size="small" color='success' variant="outlined"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    value={value} onChange={onChange}
                />
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Grid)(({
    display: "flex",
    flexDirection: "column"
}))

const Label = styled(Typography)(({
    fontSize: "12px",
    fontWeight: "500"
}))

const Input = styled(TextField)(({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "12px"
}))

export default InputField