import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Formik & Yup Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loader Import
import { MoonLoader } from 'react-spinners';

// MUI Componenets Import
import { Container, Grid, Box, Card, CardMedia, Typography, TextField, Button } from '@mui/material';

// Assets Import
import background from "../../assets/background.webp";
import logo from "../../assets/logo.webp";
import { baseUrl } from '../../constants/api';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    companyName: Yup.string().required('Company Name is required'),
    companyWebsite: Yup.string().url('Invalid URL').required('Company Website is required'),
});

function Signup() {

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          phone: '',
          companyName: '',
          companyWebsite: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSignup(formik.values);
        },
    });
    const [navigating, setNavigating] = useState(false)

    const handleSignup = (formValues) => {
        setNavigating(true)
        axios.post(`${baseUrl}auth/register`,
            {
                name: formValues.name,
                email: formValues.email,
                username: formValues.email, 
                phone: formValues.phone,
                company_name: formValues.companyName,
                website: formValues.companyWebsite,
                user_role: 3
            },
            ).then((response) => {                
                toast.success("You must have recieved an email with a password. Please login with that password.");            
                setTimeout(() => {
                    setNavigating(false)
                    navigate('/');
                }, 3000);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data) {
                        const errorMessages = [];
                        for (const key in err.response.data) {
                            if (Array.isArray(err.response.data[key])) {
                                errorMessages.push(...err.response.data[key]);
                            }
                        }
                        if (errorMessages.length > 0) {
                            toast.error(errorMessages.join("\n"));
                        } else {
                            toast.error("Registration Failed! Please try again.");
                        }
                    } else {
                        toast.error(err.response.data.message || "Registration Failed! Please try again.");
                    }
                } else {
                    toast.error("An error occurred. Please try again later.");
                }
                setNavigating(false);
            }
        );
    }

    return (
        <>
            <Wrapper maxWidth={false}>
                <Grid container display={"flex"} justifyContent={"center"}>
                    <FormContainer item lg={8} md={8} sm={8} xs={12}>
                        <FormWrapper>
                            <LogoHolder>
                                <Logo component='img' image={logo} alt="logo" />
                            </LogoHolder>
                            <Heading>Join our community</Heading>
                            <Text>Start your journey with AI3RP from now</Text>
                            <Box py={2}>
                                <Input label="Name" name="name" fullWidth color="primary" placeholder='John Doe'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                                <Input type="email" name="email" label="Email" fullWidth color="primary" placeholder='example@mail.com'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                /> 
                                <Input type="number" name="phone" label="Phone" fullWidth color="primary" placeholder='+9661234567'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                                <Input type="text" name="companyName" label="Company Name"  fullWidth color="primary" placeholder='Company Name'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.companyName}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                                <Input type="email" name="companyWebsite" label="Company Website"  fullWidth color="primary" placeholder='https://www.example.com'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.companyWebsite}
                                    error={formik.touched.companyWebsite && Boolean(formik.errors.companyWebsite)}
                                    helperText={formik.touched.companyWebsite && formik.errors.companyWebsite}
                                />
                            </Box>
                            <Button sx={{width: "100%", textTransform: "none"}} variant="contained" 
                                color="secondary" onClick={() => formik.handleSubmit()}
                            >
                                {navigating ? (
                                <>                                    
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Sign Up"
                            )}
                            </Button>
                            <Box style={{ padding: '20px 0 0', display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'8px' }}>
                                <Label>Already have an account?</Label>
                                <Label 
                                    sx={{cursor:"pointer", color: "#009444"}}
                                    onClick={() => navigate('/')}    
                                > 
                                    Sign in
                                </Label>                            
                            </Box>
                        </FormWrapper>
                    </FormContainer>
                </Grid>
            </Wrapper>        
        </>
    );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "0 !important",
    background: `url(${background}) center/cover no-repeat`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
}))

const FormContainer = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",   
}))

const FormWrapper = styled(Box)(({ theme }) => ({
    maxWidth: "450px",
    minWidth: "320px",
    padding: "20px 50px",
    textAlign: "center",
    background: 'white',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        width: '310px',
        padding: '20px'
    }
}))

const LogoHolder = styled(Card)(() => ({
    width: "100%",
    border: "none",
    boxShadow: "none",
    textAlign: "center"
}))

const Logo = styled(CardMedia)(() => ({
    width: "auto",
    margin: "0 auto"
}))

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "700",
    color:  "#2A3342",
    [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
    },
}))

const Text = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "400",
    color: "#556987",
    [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
    },
}))

const Input = styled(TextField)(() => ({
    width: "100%",
    marginBottom: "15px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "10px",
}))

export default Signup;
