import React from "react";
import { Box } from "@mui/material";

const AlertMessage = ({ sx, children }) => {
  return (
    <Box
      sx={{
        background: "#dbe8e6",
        padding: "12px 18px",
        width: "fit-content",
        borderRadius: "5px",
        border: "1px solid #2a9d8f",
        fontSize: "13px",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default AlertMessage;
