import { apiSlice } from "./apiSlice";

export const serviceTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceTypes: builder.query({
      query: () => {
        return {
          url: "service-types",
          method: "GET",
        };
      },
      providesTags: ["ServiceTypes"],
    }),

    createServiceType: builder.mutation({
      query: (body) => {
        return {
          url: "service-type/create",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["ServiceTypes"],
    }),

    updateServiceType: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `service-type/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ServiceTypes"],
    }),

    deleteServiceType: builder.mutation({
      query: (id) => {
        return {
          url: `service-type/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ServiceTypes"],
    }),
  }),
});

export const {
  useGetServiceTypesQuery,
  useCreateServiceTypeMutation,
  useUpdateServiceTypeMutation,
  useDeleteServiceTypeMutation,
} = serviceTypeApiSlice;
