import React from 'react';
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from '@mui/material';

// Components Import
import Header from '../components/Header';

const headerData = {
    heading: "Track your sales",
    subheading: "",
}

const Dashboard = () => {
    
    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />           
            </Wrapper>
        </>
    );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: '1rem',
    height: "calc(100vh - 60px)",
    overflow: "auto"
}))

export default Dashboard;
