import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
// MUI Components Import
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  TableCell,
  TableContainer,
  Tooltip,
} from "@mui/material";
// Components Import
import Loader from "../../../Loaders/Loader";
// Loader Import
// React Toastify Imports
import { baseUrl } from "../../../../constants/api";
import CustomChip from "../../../../components/CustomChip";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { Form, Formik } from "formik";
import { onKeyDown } from "../../../../utils";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import ProductWareHouseModal from "./Modals/ProductWareHouseModal";
import { BiBuildings } from "react-icons/bi";
import * as Yup from "yup";
import { usePostProductRequestFromWareHouseMutation } from "../../../../redux/api/wareHouseApiSlice";
import { toast } from "react-toastify";
import { MoonLoader } from "react-spinners";

const getStatusText = (status) => {
  switch (status) {
    case 1:
      return "Order Placed";
    case 2:
      return "Accepted";
    case 3:
      return "Rejected";
    case 4:
      return "Dispatched";
    case 5:
      return "Delivered";
    default:
      return "Unknown";
  }
};

const tableHead = [
  "#",
  "Image",
  "Product Name",
  "Category",
  "Brand",
  "Price",
  "Requested Qty",
  "Available Qty",
  // "Warehouse Available Qty",
  "Required Qty",
  "Select Warehouse",
  "Request Qty",
];

const GRSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      // recQty: Yup.number().required("Rec required"),
      recQty: Yup.number(),
    })
  ),
});

const RequestProductFromWarehouse = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();

  const token = window.localStorage.getItem("user-token");

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState();
  const [status, setStatus] = useState();
  const [orderProducts, setOrderProducts] = useState([]);
  const [formValues, setFormValues] = useState({
    products: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [editMode, setEditMode] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);

  // console.log("editMode", editMode);

  // console.log("formValues", formValues?.products);

  const fetchOrderDetails = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}orders/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const orderData = response.data;
      const products = orderData.products || [];
      setDetail(orderData);
      setOrderProducts(orderData?.order?.orderproducts);
      setFormValues((prevValues) => ({
        ...prevValues,
        products: products.map((product) => ({
          ...product,
          recQty: product.recQty || "",
          requestedItem: {
            product: product?.id,
            requested_items: [],
          },
        })),
      }));
      setStatus(orderData.order.status);
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call the function to fetch order details with the ordId
    fetchOrderDetails(order_id);
  }, []);

  const formatAddress = (address) => {
    if (!address) return "";

    try {
      const guestAddress = JSON.parse(address);

      const { city, address: street, landmark } = guestAddress;

      let formattedAddress = `${city}, ${street}`;
      if (landmark) {
        formattedAddress += `, ${landmark}`;
      }
      return formattedAddress;
    } catch (error) {
      return "";
    }
  };

  const data = [
    { Heading: "Order ID:", OrderData: `${detail?.order.ordId}` },
    { Heading: "Full Name:", OrderData: `${detail?.profile.fullname}` },
    { Heading: "Email:", OrderData: `${detail?.profile.user_email}` },
    {
      Heading: "Delivery Address:",
      OrderData: `${
        formatAddress(detail?.order.guest_address) ||
        detail?.order.shipping_address
      }`,
    },
    { Heading: "Phone:", OrderData: `${detail?.profile.phone_number}` },
    { Heading: "Total Products:", OrderData: `${detail?.products.length}` },
    {
      Heading: "Created at:",
      OrderData: `${new Date(detail?.order.created_at).toLocaleString()}`,
    },
    {
      Heading: "OrderNotes:",
      OrderData: `${detail?.order?.notes ? detail?.order?.notes : "None"}`,
    },
  ];

  // todo: CREATE WAREHOUSE API BIND
  const [createWareHouse, { isLoading }] =
    usePostProductRequestFromWareHouseMutation();

  const AddDoctorHandler = async (values) => {
    const extractRequestedItems = (outerProducts) => {
      return outerProducts
        .map((product) => {
          const { requested_items } = product.requestedItem;

          // Filter out items with requestedQuantity as empty string or 0
          const filteredRequestedItems = requested_items.filter(
            (item) =>
              item.requestedQuantity !== "" && item.requestedQuantity !== "0"
          );

          return {
            product: product.product.id,
            requested_items: filteredRequestedItems,
            requested_from: product.stock,
          };
        })
        .filter((product) => product.requested_items.length > 0); // Remove objects with empty requested_items array
    };

    const payload = extractRequestedItems(values?.products);

    // console.log("payload", payload);
    // return;

    try {
      const create = await createWareHouse({ body: payload });

      if (!create?.error) {
        toast.success("Request Products From Warehouse Sent successfully!");
        navigate("/dashboard/sales/orders-list");
        localStorage.removeItem("formValues");
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Warehouse Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const getStatus = (status) => {
    setSecondLoading(true);
    axios
      .put(
        `${baseUrl}order/status/${order_id}`,
        {
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        // fetchOrderDetails(order_id);
        navigate("/dashboard/sales/orders-list");

        setSecondLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSecondLoading(false);
      });
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        {loading ? (
          <Loader />
        ) : (
          <Grid
            container
            my={1}
            gap={"20px"}
            justifyContent={"space-between"}
            display={"flex"}
          >
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <h3>Request Products from another Warehouse</h3>
                <Typography
                  sx={{
                    color: "#191C1F",
                    fontSize: "16px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  Order Status:
                  <>{<CustomChip label={getStatusText(status)} />}</>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              md={12}
              display={"flex"}
              flexDirection={"row"}
              gap={"20px"}
            >
              <Grid
                item
                md={6}
                sx={{
                  border: " 1px solid #DDDDDD",
                  borderRadius: "8px",
                  height: "auto",
                  background: "#fff",
                }}
              >
                <Box
                  sx={{ borderBottom: "1px solid #E4E7E9", padding: "10px" }}
                >
                  <Typography
                    sx={{
                      color: "#191C1F",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Order Details
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  padding={"0px 10px 20px"}
                  marginTop={"10px"}
                  gap={"15px"}
                >
                  <Grid container spacing={0} sx={{ gap: "12px" }}>
                    {data.map((row, index) => (
                      <Grid item container key={index}>
                        <Grid item xs={3}>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {row.Heading}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <Typography
                              sx={{ fontSize: "14px", color: "#5F6C72" }}
                            >
                              {row.OrderData}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                border: " 1px solid #DDDDDD",
                borderRadius: "8px",
                height: "auto",
                background: "#fff",
              }}
            >
              <Box sx={{ borderBottom: "1px solid #E4E7E9", padding: "10px" }}>
                <Typography
                  sx={{
                    color: "#191C1F",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Products
                </Typography>
              </Box>
              <Formik
                initialValues={formValues}
                onSubmit={(values) => {
                  AddDoctorHandler(values);
                }}
                validationSchema={GRSchema}
                enableReinitialize
              >
                {(props) => {
                  const { values, errors, handleBlur, handleChange, touched } =
                    props;

                  return (
                    <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                      <Box>
                        <MUITable
                          tableHead={tableHead}
                          // background={"#DDDDDD"}
                        >
                          {loading ? (
                            <Loader />
                          ) : values?.products?.length > 0 ? (
                            values?.products?.map((item, index) => {
                              const requestQty = item?.quantity;
                              // ORDERPRODUCTS
                              const availableQty = Number(
                                orderProducts[index]?.stock?.productQuantity
                              );

                              const result = requestQty - availableQty;

                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell>{index + 1}</StyledTableCell>
                                  <StyledTableCell>
                                    <Box
                                      alt="product_image"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        marginRight: "8px",
                                        objectFit: "fill",
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.product?.images[0]?.image || ""
                                        }
                                        alt="Product"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </Box>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item?.product.commons.en.productName}
                                    <br />
                                    {item?.product.commons.ar.productName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item?.product.productCategory} /{" "}
                                    {item?.product.productSubcategory}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {item?.product.productCategory} /{" "}
                                    {item?.product.productSubcategory}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item?.amount} SAR
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {item?.quantity}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    <Box
                                      sx={{
                                        color:
                                          requestQty > availableQty
                                            ? "#d3302f"
                                            : "#000",
                                        fontWeight:
                                          requestQty > availableQty
                                            ? "bold"
                                            : "normal",
                                      }}
                                    >
                                      {Number(
                                        orderProducts[index]?.stock
                                          ?.productQuantity
                                      )}
                                    </Box>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <PrimaryInput
                                      type="text"
                                      placeholder="Required Qty"
                                      disabled={true}
                                      value={
                                        result === 0
                                          ? "0"
                                          : result < 0
                                          ? "0"
                                          : result
                                      }
                                      size="small"
                                      sx={{
                                        width: "120px",
                                        height: "45px",
                                      }}
                                      height="40px"
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    <Button
                                      variant="outlined"
                                      startIcon={<BiBuildings />}
                                      onClick={() => {
                                        setSelectedProduct(item);
                                        setModalOpen(true);
                                      }}
                                    >
                                      Warehouse
                                    </Button>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Tooltip
                                      title={
                                        <div>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "Click on the Warehouse Button & add the required quantity from the warehouse list",
                                            }}
                                          />
                                        </div>
                                      }
                                      placement="top"
                                      arrow
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: "#fff",
                                            boxShadow:
                                              "0 0 25px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "5px",
                                            padding: "12px",
                                            maxWidth: "215px",
                                            fontSize: "0.8rem",
                                            color: "#000",
                                            "& .MuiTooltip-arrow": {
                                              color: "#fff",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <div>
                                        <PrimaryInput
                                          type="number"
                                          placeholder="Request Qty"
                                          name={`products.${index}.recQty`}
                                          value={
                                            formValues?.products[index]
                                              ?.requestedItem
                                              ?.totalRequestedQuantity
                                          }
                                          // value={values.products[index].recQty}
                                          // onChange={(e) => {
                                          //   if (
                                          //     e.target.value > item?.dnQuantity
                                          //   ) {
                                          //     e.target.value = item?.dnQuantity;
                                          //   }
                                          //   handleChange(e, index, "recQty");
                                          // }}
                                          // onBlur={handleBlur}
                                          // error={
                                          //   errors.products &&
                                          //   errors.products[index]?.recQty
                                          // }
                                          // helperText={
                                          //   errors.products &&
                                          //   errors.products[index]?.recQty
                                          // }
                                          readOnly={true}
                                          size="small"
                                          sx={{
                                            width: "120px",
                                            height: "45px",
                                          }}
                                          height="40px"
                                        />
                                      </div>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })
                          ) : (
                            ""
                          )}
                        </MUITable>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          margin: "30px",
                          gap: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            getStatus(3);
                          }}
                          sx={{
                            height: "40px",
                            width: "180px",
                            borderRadius: "5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {secondLoading ? (
                            <MoonLoader color="#d53837" size={20} />
                          ) : (
                            "Reject Request"
                          )}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{
                            height: "40px",
                            width: "180px",
                            borderRadius: "5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {isLoading ? (
                            <MoonLoader color="#fff" size={20} />
                          ) : (
                            "Submit Request"
                          )}
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        )}
        {/* Modal */}
        <ProductWareHouseModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          selectedProduct={selectedProduct}
          formValues={formValues}
          setFormValues={setFormValues}
          setEditMode={setEditMode}
        />
      </Wrapper>
    </>
  );
};

// Styled Components
const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  boxShadow: "none",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default RequestProductFromWarehouse;
