import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography, styled, Grid } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../../../../constants/api';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
};

function CustomerEditModal({ open, setOpen, fullName, custEmail, custPhone, fetchCustomer, id, setSelectedCustomer }) {
    const token = window.localStorage.getItem("user-token");

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(fullName);
    const [phone, setPhone] = useState(custPhone);
    const [email, setEmail] = useState(custEmail);
    useEffect(() => {
        if (open) {
            setName(fullName);
            setPhone(custPhone);
            setEmail(custEmail);
        }
    }, [fullName, custPhone, custEmail, open]);

    const updateCustomer = () => {
        setLoading(true);
        axios
            .put(
                `${baseUrl}customer/update/${id}`,
                {
                    fullname: name,
                    phone_number: phone,
                    email: email,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then(() => {
                toast.success("Customer has been Updated!");
                setOpen(false);
                setLoading(false);
                fetchCustomer();
            })
            .catch((error) => {
                console.log(error); setLoading(false);
            }).finally(() => {
                setSelectedCustomer(null);
            });
    };

    const handleUpdate = (setOpen) => {
        updateCustomer(setOpen);

    };


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
                <Heading>Edit Customer Information</Heading>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: 'space-between'
                    // gap: '10px'
                }}>
                    <InputWrapper item md={5.5} my={2}>
                        <Label>Full Name</Label>
                        <Input
                            mt={3}
                            type="text"
                            size="small"
                            color="success"
                            variant="outlined"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </InputWrapper>
                    <InputWrapper item md={5.5} my={2}>
                        <Label>Phone Number</Label>
                        <Input
                            mt={3}
                            type="text"
                            size="small"
                            color="success"
                            variant="outlined"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}

                        />
                    </InputWrapper>
                    <InputWrapper item md={12} my={2}>
                        <Label>Email</Label>
                        <Input
                            type="text"
                            size="small"
                            color="success"
                            variant="outlined"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}

                        />


                    </InputWrapper>

                </Grid>

                <Box display={"flex"} justifyContent={"end"} gap={"15px"}>
                    <BottomButton variant="outlined" color="secondary"

                        {
                        ...({
                            onClick: () => {
                                if (loading) return;
                                setOpen(false)
                                setSelectedCustomer(null)
                            }
                        })
                        }
                    >
                        Cancel
                    </BottomButton>
                    <BottomButton
                        onClick={() => handleUpdate(setOpen)}
                        variant="contained" color="secondary"
                    >
                        {
                            loading ? 'Updating...' : 'Update'
                        }
                    </BottomButton>
                </Box>
            </Box>
        </Modal >
    );
}

const BottomButton = styled(Button)(({ theme }) => ({
    height: '40px',
    width: '100px',
    mt: '23px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
        width: '45%',
    }
}))

const Heading = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: '600',
}));

const Label = styled(Typography)({
    fontSize: "12px",
    fontWeight: "500",
});

const Input = styled(TextField)({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "12px",
    width: "100%",
});
const InputWrapper = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: '0'
});

const SaveBTN = styled(Button)(() => ({
    width: '130px',
    height: '40px',
    margin: '0 auto',
    background: '#168F48',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#168F48',
    },
}));

const CancelBTN = styled(Button)(() => ({
    width: '130px',
    height: '40px',
    background: 'transparent',
    color: '#168F48',
    border: '1px solid #168F48',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#168F48',
        color: '#fff'
    },
}));
export default CustomerEditModal