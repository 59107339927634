import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";

// MUI Componenets Import
import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  Grid,
  Tooltip,
} from "@mui/material";

// MUI Icons Import
import UserIcon from "@mui/icons-material/AccountCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import NotifIcon from "@mui/icons-material/NotificationsNoneOutlined";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../constants/api";
import NotificationTooltip from "./NotificationTooltip";

function Navbar() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("user-token");

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleLogout = () => {
    axios
      .post(
        `${baseUrl}auth/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        // window.localStorage.removeItem("user-token");
        localStorage.clear();
        toast.success("You ave been logged out!");
        navigate("/");
      })
      .catch(() => {
        toast.error("Logout failed!");
      });
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Nav>
          {/* <Box sx={{ width: "30vw" }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: "#bdbdbd" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search here"
                                inputProps={{ "aria-label": "search" }}
                                sx={{ width: "90%", left: "30px" }}
                            />
                        </Search>
                    </Box> */}
          <Box
            sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "15px",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <NotifTooltip
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<NotificationTooltip />}
                  >
                    <NotifIcon
                      sx={{ fontSize: "18px" }}
                      onClick={handleTooltipOpen}
                    />
                  </NotifTooltip>
                </ClickAwayListener>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Profile" placement="top">
                  <UserIcon
                    sx={{ fontSize: "18px" }}
                    onClick={() => navigate("/dashboard/profile")}
                  />
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Logout" placement="top">
                  <Logout
                    sx={{ fontSize: "18px" }}
                    onClick={() => handleLogout()}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Nav>
      </Wrapper>
    </>
  );
}

// Styled Components
const Wrapper = styled(Container)(({ theme }) => ({
  padding: "0 !important",
  backgroundColor: "#fff",
  height: "60px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const Nav = styled(AppBar)(({ theme }) => ({
  position: "static",
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  backgroundColor: "#ffffff",
  color: "#000000",
  boxShadow: "none",
  padding: "0 40px",
}));
const NotifTooltip = styled(Tooltip)(({ theme }) => ({
  width: "auto",
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#ff0000", // Change this to your desired background color
  },
}));

// const Search = styled("div")(({ theme }) => ({
//   height: "30px",
//   position: "relative",
//   borderRadius: "10px",
//   backgroundColor: "#F7F9FB",
//   display: "flex",
//   alignItems: "center",
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 1.5),
//   height: "100%",
//   position: "absolute",
//   left: "0",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1),
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "35ch",
//     },
//   },
// }));

export default Navbar;
