import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
// MUI Components Import
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";

function ExpectedDeliveryDate() {
  const token = window.localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [deliveryDays, setDeliveryDays] = useState(0);
  //   const [isActive, setIsActive] = useState(false);

  const handleUpdate = async () => {
    if (deliveryDays <= 0 || deliveryDays > 30) {
      toast.error("Please enter a valid delivery days! 1 to 30");
      return;
    }

    setSaving(true);
    try {
      await axios.put(
        `${baseUrl}auth/settings`,
        {
          delivery_day: deliveryDays,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Delivery days updated successfully!");
      setTimeout(() => {
        setSaving(false);
      }, 2000);
      fetchTax();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
      setSaving(false);
    }
  };

  const fetchTax = async () => {
    try {
      const response = await axios.get(`${baseUrl}auth/settings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setDeliveryDays(response?.data?.delivery_day);
      // setIsActive(response?.data?.first_time_discount_active);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTax();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper mt={3}>
        <Heading>Expected Delivery Date for Order Dispatching</Heading>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={4}>
            <Label>Enter days</Label>
            <Input
              placeholder="Enter days"
              value={deliveryDays}
              onChange={(e) => {
                setDeliveryDays(e.target.value);
              }}
              type="number"
            />
          </Grid>
          <Grid item md={4}>
            {/* <Label>Active</Label>
            <Checkbox
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            /> */}
          </Grid>
          <Grid item md={4}>
            <SaveBTN onClick={() => handleUpdate()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Update"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  float: "right",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

export default ExpectedDeliveryDate;
