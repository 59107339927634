import React, { useState } from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Modal, Box, Typography, TextField, Button } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseUrl } from '../../../../../constants/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"8px",
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    gap: "12px"
};

function WarehouseModal({ open, onClose, data }) {

    const token = window.localStorage.getItem("user-token");

    const [saving, setSaving] = useState(false)
    const [warehouseName, setWarehouseName] = useState("")
    const [warehouseLocation, setWarehouseLocation] = useState("")

    const handleSave= () => {
        setSaving(true)
        axios.post(`${baseUrl}warehouse/create`,
            {
                name: warehouseName,
                location: warehouseLocation
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            toast.success("Warehouse created successfully!");
            setTimeout(() => {
                setSaving(false)
            }, 2000);
            data()
            setWarehouseName("")
            setWarehouseLocation("")
            onClose()
        })
        .catch((err) => {
            toast.error("Failed to create Warehouse!");
            setSaving(false)
        })
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Heading>Add a new Warehouse</Heading>
                    <Input mt={3} type="text" size="large" color='primary' variant="outlined" placeholder='Warehouse Name' 
                        value={warehouseName} onChange={(e) => setWarehouseName(e.target.value)}
                    />
                    <Input mt={3} type="text" size="large" color='primary' variant="outlined" placeholder='Warehouse Location' 
                        value={warehouseLocation} onChange={(e) => setWarehouseLocation(e.target.value)}
                    />
                    <SaveBTN onClick={() => handleSave()}>
                        {saving ? (
                            <>                                    
                                <MoonLoader color="#fff" size={20} />
                            </>
                            ) : (
                            "Save"
                        )}
                    </SaveBTN>
                </Box>
            </Modal>
        </>
    )
}

const Heading = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "600"
}))

const Input = styled(TextField)(({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "5px"
}))

const SaveBTN = styled(Button)(() => ({
    width: '150px', 
    height: '40px', 
    margin: '0 auto', 
    background: "#812990", 
    color: "#fff", 
    borderRadius: '5px',
    "&:hover": {
        backgroundColor: "#812990"
    }
}))

export default WarehouseModal