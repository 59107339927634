import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Modal, Box, Grid, Typography, Button } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'start',
    alignItems: 'start',
    gap: "12px"
};


function ConfirmationModal({ open, setOpen, prodId, heading, text, handleDeleteProduct }) {

    const handleDelete = (prodId) => {
        handleDeleteProduct(prodId);
        setOpen(false);
    };

    return (
        <>

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Heading>{heading}</Heading>
                    <SubHeading>{text}</SubHeading>
                    <Grid container >
                        <Grid item sm={12} md={12} display={"flex"} justifyContent={"end"} gap={"15px"}>
                            <BottomButton variant="outlined" color="secondary"

                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </BottomButton>
                            <BottomButton
                                onClick={() => handleDelete(prodId)}
                                variant="contained" color="secondary"
                            >
                                Delete
                            </BottomButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

const BottomButton = styled(Button)(({ theme }) => ({
    height: '40px',
    width: '20%',
    mt: '23px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
        width: '45%',
    }
}))

const Heading = styled(Typography)(() => ({
    fontSize: "22px",
    fontWeight: "800",
}))

const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: "15px",
    fontWeight: "500",
    [theme.breakpoints.down('sm')]: {
        fontSize: "10px",
    }
}))

export default ConfirmationModal
