import * as Yup from "yup";

export const addProductSchema = Yup.object().shape({
  productName: Yup.string().required("Name is required"),
  productType: Yup.object().required("Type is required"),
  productBarcode: Yup.string().required("Barcode is required"),
  brand: Yup.object(),
  model: Yup.object(),
  productCategory: Yup.object().required("Category is required"),
  productSubcategory: Yup.object().required("Subcategory is required"),
  productQuantity: Yup.string().required("Quantity is required"),
  onlinePrice: Yup.string().required("Online Price is required"),
  description: Yup.string().required("Description is required"),
  pictures: Yup.array()
    .of(
      Yup.mixed().test("fileType", "Picture is required", function (value) {
        // Check if the value is an instance of File or a non-empty string
        return (
          value instanceof File ||
          (typeof value === "string" && value.length > 0)
        );
      })
    )
    .min(1, "Image is required"),
  // todo: English and Arabic
  arabicProductName: Yup.string().required("Arabic Name is required"),
  description_ar: Yup.string().required("Arabic Description is required"),
  // not required
  minQty: Yup.string(),
  orderLimit: Yup.string(),
  reorderLevel: Yup.string(),
  purchasePrice: Yup.string(),
  sellingPrice: Yup.string(),
  unit: Yup.object(),
  code: Yup.string(),
  glAccount: Yup.string(),
  origin: Yup.string(),
  videoURL: Yup.string(),
  valuationRate: Yup.string(),
  priceValidity: Yup.string().nullable(),
  salesItem: Yup.boolean(),
  purchaseItem: Yup.boolean(),
  serviceTerms: Yup.string(),
  serviceTerms_ar: Yup.string(),
  weight: Yup.string(),
  VHeight: Yup.string(),
  VWidth: Yup.string(),
  VLength: Yup.string(),
});

// todo: SERVICE TYPE SCHEMA
export const serviceSchema = Yup.object().shape({
  productName: Yup.string().required("Name is required"),
  productType: Yup.object().required("Type is required"),
  productBarcode: Yup.string().required("Barcode is required"),
  brand: Yup.object(),
  model: Yup.object(),
  productCategory: Yup.object().required("Category is required"),
  productSubcategory: Yup.object().required("Subcategory is required"),
  productQuantity: Yup.string(), // Not required
  onlinePrice: Yup.string().required("Online Price is required"),
  description: Yup.string().required("Description is required"),
  pictures: Yup.array()
    .of(
      Yup.mixed().test("fileType", "Picture is required", function (value) {
        // Check if the value is an instance of File or a non-empty string
        return (
          value instanceof File ||
          (typeof value === "string" && value.length > 0)
        );
      })
    )
    .min(1, "Image is required"),
  // todo: English and Arabic
  arabicProductName: Yup.string().required("Arabic Name is required"),
  description_ar: Yup.string().required("Arabic Description is required"),
  // not required
  minQty: Yup.string(),
  orderLimit: Yup.string(),
  reorderLevel: Yup.string(),
  purchasePrice: Yup.string(),
  sellingPrice: Yup.string(),
  unit: Yup.object(),
  code: Yup.string(),
  glAccount: Yup.string(),
  origin: Yup.string(),
  videoURL: Yup.string(),
  valuationRate: Yup.string(),
  priceValidity: Yup.string().nullable(),
  salesItem: Yup.boolean(),
  purchaseItem: Yup.boolean(),
  serviceTerms: Yup.string(),
  serviceTerms_ar: Yup.string(),
  weight: Yup.string(),
  VHeight: Yup.string(),
  VWidth: Yup.string(),
  VLength: Yup.string(),
});
