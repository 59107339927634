import { Box, Typography, styled } from '@mui/material';
import React from 'react'

import StorefrontIcon from '@mui/icons-material/Storefront';

function NotifCard() {
    return (
        <>
            <CardWrapper>
                <IconWrapper>
                    <StorefrontIcon sx={{ fontSize: '18px' }} />
                </IconWrapper>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>
                    You have received a new order for (Product Name)
                </Typography>
            </CardWrapper>

        </>
    )
}

const CardWrapper = styled(Box)(({ theme }) => ({
    padding: '10px',
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
    gap: '10px',
    border: '1px solid #77878F',
    borderRadius: '10px',


}));
const IconWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    height: '40px',
    width: '64px',
    backgroundColor: '#77878F',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: "center",
}));

export default NotifCard