import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Component Import
import { Grid, Box, Typography } from "@mui/material";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons Import
import DownloadIcon from "@mui/icons-material/Difference";
import { baseUrl } from "../../../../constants/api";
import DownloadCSVModal from "./DownloadCSVModal";

function DownloadCSVCard() {
  const token = window.localStorage.getItem("user-token");
  async function getFile() {
    try {
      const response = await axios.get(`${baseUrl}xlsx/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Token ${token}`,
        },
        responseType: "blob", // Specify response type as 'blob' to handle binary data
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "products.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("xlsx file downloaded successfully.");
      } else {
        toast.error("Failed to download xlsx file.");
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  return (
    <>
      <Card item md={3.5}>
        <Title>Download XLSX File</Title>
        <IconWrapper onClick={() => getFile()}>
          <DownloadIcon sx={{ fontSize: "90px", color: "#662D91" }} />
        </IconWrapper>
      </Card>
    </>
  );
}

// Styled Components

const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#662D91",
  borderRadius: "10px",
}));

const Title = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#fff",
}));

const IconWrapper = styled(Box)(() => ({
  width: "80%",
  height: "60%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "20px",
  cursor: "pointer",
}));

export default DownloadCSVCard;
