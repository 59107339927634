import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Components Import
import {
  Box,
  Modal,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";
import { baseUrl } from "../../../../../constants/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minHeight: "320px",
  backgroundColor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

function LocationsModal({ open, setOpen, id }) {
  const token = window.localStorage.getItem("user-token");

  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);

  const fetchLocationData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}product/stock/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setLocationData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocationData(id);
  }, [id]);

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableHeadings>Warehouse Location</TableHeadings>
                <TableHeadings>Warehouse Name</TableHeadings>
                <TableHeadings>Warehouse Stock</TableHeadings>
              </TableRow>
            </TableHead>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MoonLoader color="#000" size={20} />
              </Box>
            ) : locationData.length === 0 ? (
              <TableRow align="center">
                <TableContent colSpan={3} align="center">
                  This product doesn't have any warehouse information!
                </TableContent>
              </TableRow>
            ) : (
              <TableBody>
                {locationData.map((loc, index) => (
                  <TableRow key={index}>
                    <TableContent>{loc.location}</TableContent>
                    <TableContent>{loc.name}</TableContent>
                    <TableContent>{loc.stock}</TableContent>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadings>Warehouse Location</TableHeadings>
                <TableHeadings>Warehouse Name</TableHeadings>
                <TableHeadings>Batch Number</TableHeadings>
                <TableHeadings>Warehouse Stock</TableHeadings>
                <TableHeadings>Expiry Date</TableHeadings>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableRow>
                <TableContent colSpan={12} sx={{ width: "100%" }}>
                  <MoonLoader color="#000" size={20} />
                </TableContent>
              </TableRow>
            ) : locationData?.length === 0 ? (
              <TableRow align="center">
                <TableContent colSpan={12} align="center">
                  This product has no location information
                </TableContent>
              </TableRow>
            ) : (
              <TableBody>
                {locationData?.map((stock, index) => {
                  return (
                    <TableRow key={index}>
                      <TableContent>{stock?.warehouse?.location}</TableContent>
                      <TableContent>{stock?.warehouse?.name}</TableContent>
                      <TableContent>{stock?.batchNumber}</TableContent>
                      <TableContent>
                        {Number(stock?.productQuantity)}
                      </TableContent>

                      <TableContent>{stock?.expiryDate}</TableContent>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Box>
      </Modal>
    </div>
  );
}

// Styled Components

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#2E2E2E",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#71747D",
}));

export default LocationsModal;
