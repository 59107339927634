import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { baseUrl } from "../../../../constants/api";
import { ORDER_PAGINATION_LIMIT } from "../../../../constants/constant";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { useGetOrdersQuery } from "../../../../redux/api/ordersApiSlice";
// MUI Components import
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
// Components Import
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import Loader from "../../../Loaders/Loader";
import Header from "../../components/Header";
// Icons Import
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
// React Toastify Imports
import { BiBuildings } from "react-icons/bi";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomChip from "../../../../components/CustomChip";
import ExpectedDeliveryModal from "./Modals/ExpectedDeliveryModal";
import { OrderStatusEnum } from "../../../../utils/enum";
import { MoonLoader } from "react-spinners";
import { GiNotebook } from "react-icons/gi";
import StaffNoteModal from "./Modals/StaffNoteModal";
import { SlNote } from "react-icons/sl";

const headerData = {
  heading: "Orders List",
  subheading: "Keep track of all the orders in one place",
};

function OrdersList() {
  const token = window.localStorage.getItem("user-token");

  const navigate = useNavigate();
  const { handleLoader } = useLoaderContext();

  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  // Modal State
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchOrderLists,
  } = useGetOrdersQuery({
    limit: ORDER_PAGINATION_LIMIT,
    offset: page,
  });

  useEffect(() => {
    refetchOrderLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setCount(data?.total_count);
      setOrders(data?.results);
    }
  }, [data]);

  const fetchOrdersList = () => {
    setSearchTerm("");
    setOrderStatus("");
    setPaymentStatus("");
    handleLoader(true);
    axios
      .get(`${baseUrl}vendor/orders`, {
        params: {
          limit: ORDER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const searchOrder = () => {
    handleLoader(true);
    axios
      .post(
        `${baseUrl}vendor/orders`,
        {
          search_term: searchTerm,
        },
        {
          params: {
            limit: ORDER_PAGINATION_LIMIT,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  const applyFilters = () => {
    handleLoader(true);
    let payload = {};

    const order_Status = orderStatus;

    if (orderStatus !== "") {
      payload.status = order_Status;
    }

    if (paymentStatus !== "") {
      payload.payment_status = paymentStatus;
    }

    axios
      .post(`${baseUrl}vendor/orders`, payload, {
        params: {
          limit: ORDER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setOrders(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const downloadInvoice = (id) => {
    setInvoiceLoading(true);
    axios
      .get(`${baseUrl}invoice/${id}`, {
        responseType: "blob", // Set the response type to blob to handle binary data
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        toast.success("Invoice PDF Downloaded!");
        setInvoiceLoading(false);
      })
      .catch(() => {
        toast.error("Error downloading Invoice!");
        setInvoiceLoading(false);
      });
  };

  const tableHead = [
    "Order ID",
    "Date",
    "Customer Name",
    "Customer Email",
    "Customer Address",
    "Order Status",
    "Payment ID",
    "Payment Status",
    "Payment Method",
    "Amount",
    "Requested Qty",
    "Available Qty",
    "Action",
  ];

  const formatAddress = (address) => {
    if (!address) return "";

    try {
      const guestAddress = JSON.parse(address);

      const { city, address: street } = guestAddress;

      let formattedAddress = `${city}, ${street}`;
      return formattedAddress;
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by Order Id, email"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchOrder()}>Search</Button>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="order-range-label">Order Status</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="order-status-label"
                id="order-status-select"
                value={orderStatus}
                label="Order Status"
                onChange={(e) => setOrderStatus(e.target.value)}
              >
                <MenuItem value={1}>Order Placed</MenuItem>
                <MenuItem value={2}>Order Accepted</MenuItem>
                <MenuItem value={3}>Order Rejected</MenuItem>
                <MenuItem value={4}>Order Dispatched</MenuItem>
                <MenuItem value={5}>Order Delivered</MenuItem>
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="spending-filter-label">Payment Status</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="spending-filter-label"
                id="spending-filter-select"
                value={paymentStatus}
                label="Spending filter"
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Paid</MenuItem>
                <MenuItem value={3}>Unpaid</MenuItem>
              </Select>
            </Dropdown>
            <Button onClick={() => applyFilters()}>Apply</Button>
            <Button onClick={() => fetchOrdersList()}>Reset</Button>
          </Box>
        </SearchNav>

        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
              {isSuccess && orders?.length > 0 ? (
                orders?.map((row) => {
                  const order = row?.order;
                  const hasLowStock = order?.orderproducts?.some((product) => {
                    const requestedQty = Number(product?.quantity);
                    const availableQty = Math.abs(
                      parseFloat(product?.stock?.productQuantity)
                    );
                    return requestedQty > availableQty;
                  });

                  const orderStatus = row?.order?.status;

                  return (
                    <StyledTableRow key={row?.order?.ordId}>
                      <StyledTableCell>{row?.order?.ordId}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(row.order.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.profile?.fullname}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.profile?.user_email}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatAddress(row?.order?.guest_address) ||
                          `${row?.order?.address?.city}, ${row?.order?.address?.address}`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {<CustomChip label={row?.order?.status_display} />}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.transaction?.fort_id}
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          <CustomChip
                            label={
                              row?.order?.transaction?.payment_status_display
                            }
                          />
                        }
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.transaction?.payment_method_display}
                      </StyledTableCell>

                      <StyledTableCell>
                        {parseFloat(
                          row?.order?.total_price || 0
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        SR
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.order?.orderproducts?.map((product, index) => {
                          const imageUrl =
                            product?.product_meta?.images?.[0]?.image;
                          const productName =
                            product?.product_meta?.commons?.en?.productName;

                          const productType =
                            product?.product_meta?.product_type_display;

                          // Check if the product type is "Service"
                          if (
                            productType === "Service" ||
                            productType === undefined
                          ) {
                            return (
                              <Typography
                                key={product.id}
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  margin:
                                    index < row.order.orderproducts.length - 1
                                      ? "0 4px 0 0"
                                      : "0",
                                }}
                              >
                                {"-"}
                                {index < row.order.orderproducts.length - 1 &&
                                  ","}
                              </Typography>
                            );
                          }

                          return (
                            <Tooltip
                              key={product.id}
                              title={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Avatar
                                    src={imageUrl || ""}
                                    alt="product"
                                    sx={{ marginRight: "8px" }}
                                  />
                                  <div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: productName || "",
                                      }}
                                    />
                                  </div>
                                </Box>
                              }
                              placement="top"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#fff",
                                    boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "5px",
                                    padding: "12px",
                                    maxWidth: "250px",
                                    fontSize: "0.8rem",
                                    color: "#000",
                                    "& .MuiTooltip-arrow": {
                                      color: "#fff",
                                    },
                                  },
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                  margin:
                                    index < row.order.orderproducts.length - 1
                                      ? "0 4px 0 0"
                                      : "0",
                                }}
                              >
                                {product.quantity}
                                {index < row.order.orderproducts.length - 1 &&
                                  ","}
                              </Typography>
                            </Tooltip>
                          );
                        })}
                      </StyledTableCell>
                      {/* Quantity */}
                      <StyledTableCell>
                        {row?.order?.orderproducts?.map((product, index) => {
                          const availableQty = Math.abs(
                            parseFloat(product?.stock?.productQuantity)
                          );

                          const productType =
                            product?.product_meta?.product_type_display;

                          // Check if the product type is "Service"
                          if (
                            productType === "Service" ||
                            productType === undefined
                          ) {
                            return (
                              <Typography
                                key={product.id}
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  margin:
                                    index < row.order.orderproducts.length - 1
                                      ? "0 4px 0 0"
                                      : "0",
                                }}
                              >
                                {"-"}
                                {index < row.order.orderproducts.length - 1 &&
                                  ","}
                              </Typography>
                            );
                          }

                          return (
                            <Tooltip
                              key={product.id}
                              title={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      Location:
                                    </span>{" "}
                                    {product?.stock?.warehouse?.name} /{" "}
                                    {product?.stock?.warehouse?.location}
                                  </div>
                                </Box>
                              }
                              placement="top"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#fff",
                                    boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "5px",
                                    padding: "12px",
                                    maxWidth: "250px",
                                    fontSize: "0.8rem",
                                    color: "#000",
                                    "& .MuiTooltip-arrow": {
                                      color: "#fff",
                                    },
                                  },
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                  margin:
                                    index < row.order.orderproducts.length - 1
                                      ? "0 4px 0 0"
                                      : "0",
                                }}
                              >
                                {availableQty}
                                {index < row.order.orderproducts.length - 1 &&
                                  ","}
                              </Typography>
                            </Tooltip>
                          );
                        })}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            padding: "5px 0",
                          }}
                        >
                          {/* Warehouse */}
                          {(hasLowStock &&
                            orderStatus !== OrderStatusEnum.ORDERPLACED) ||
                            (hasLowStock &&
                              orderStatus !== OrderStatusEnum.REJECTED && (
                                <Tooltip
                                  title="Request Products From Warehouse"
                                  placement="top"
                                >
                                  <IconButton
                                    color="warning"
                                    onClick={() =>
                                      navigate(
                                        `/dashboard/sales/request-product/${row.order.order_id}`
                                      )
                                    }
                                  >
                                    <BiBuildings />
                                  </IconButton>
                                </Tooltip>
                              ))}

                          {(!hasLowStock ||
                            orderStatus === OrderStatusEnum.ACCEPTED ||
                            orderStatus === OrderStatusEnum.REJECTED ||
                            orderStatus === OrderStatusEnum.DISPATCHED ||
                            orderStatus === OrderStatusEnum.DELIVERED) && (
                            <Tooltip title="View Details" placement="top">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/sales/order-detail/${row.order.order_id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip
                            title="Expected Delivery Date"
                            placement="top"
                            color="primary"
                          >
                            <IconButton
                              onClick={() => {
                                setSelectedItem(row);
                                setModalOpen(true);
                              }}
                            >
                              <IoCalendarNumberOutline />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title="Staff Note"
                            placement="top"
                            color="primary"
                          >
                            <IconButton
                              onClick={() => {
                                setSelectedItem(row);
                                setNotesModalOpen(true);
                              }}
                            >
                              <GiNotebook />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Download Invoice" placement="top">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setSelectedItem(row);
                                downloadInvoice(row?.order?.ordId);
                              }}
                            >
                              {invoiceLoading &&
                              selectedItem?.order?.ordId ===
                                row?.order?.ordId ? (
                                <MoonLoader color="#000" size={18} />
                              ) : (
                                <DownloadIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {orders?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>

            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ fontSize: "14px", marginRight: "10px" }}>
                  Total Records: {count}
                </Box>
              </Box>
              <Pagination
                count={Math.ceil(count / ORDER_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
      {/* Modals */}
      <ExpectedDeliveryModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedItem={selectedItem}
      />

      <StaffNoteModal
        modalOpen={notesModalOpen}
        setModalOpen={setNotesModalOpen}
        selectedItem={selectedItem}
      />
    </>
  );
}

// Styled Components
const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

export default OrdersList;
