import React, { useEffect, useRef, useState } from 'react'

// MUI Components Import
import { Box, Button, Grid, Typography, styled } from '@mui/material'

// Icons Import
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseUrl } from '../../../../../../constants/api';

function Payments() {

    const token = window.localStorage.getItem("user-token");

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };

    const [saving, setSaving] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([])
    const [images, setImages] = useState([]);

    const handleImageUpload = (e) => {
        const files = e.target.files;
        const fileList = [...files];
        setImages(prevImages => [...prevImages, ...fileList]);
    };
    
    const handleRemoveImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const fetchPaymentsData = () => {
        axios.get(`${baseUrl}footer/payment_link`)
        .then((response) => {
            setUploadedImages(response.data)
        })
        .catch(() => {
        });
    };

    const handlePayments = () => {
        if (images.length === 0) {
            toast.error("Please add at least one link");
            return;
        }
        setSaving(true);
        const formData = new FormData();

        for (let i = 0; i < images.length; i++) {
            if(typeof images[i] !== 'string') {
                formData.append('images[]', images[i]);
            }
        }
        axios.post(`${baseUrl}footer/payment_link/create`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Payment Icons added successfully!");
            setSaving(false);
        })
        .catch((err) => {
            if (err.response) {
                toast.error(err.response.data.message);
            }
            setSaving(false);
        });
    }

    const handleDeleteImage = (id) => {
        axios.delete(`${baseUrl}footer/payment_link/${id}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Payment Icons deleted successfully!");
            fetchPaymentsData()
        })
        .catch(() => {
            toast.error("Payment Icons could not be deleted!");
        });
    }

    useEffect(() => {
        fetchPaymentsData()
    }, [])

    return (
        <>
            <SectionWrapper mt={3}>
                <Heading>Payment Gateways Icons</Heading>
                <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                    <Grid item md={12} display={"flex"} alignItems={"center"}>
                        <Uploader item md={1} onClick={() => selectFiles()}>
                            <input type="file" accept="image/*" ref={imgUpload} style={{ display: 'none' }} onChange={handleImageUpload}
                                id="imageInput" multiple
                            />
                            <AddPhotoIcon sx={{ fontSize: '25px' }} />
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>Add Icons</Typography>
                        </Uploader>
                        <Grid item md={9}  display={"flex"} flexDirection={"row"}>
                            {uploadedImages?.map((img, index) => (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <ImgDisplay key={index} sx={{ background: `url(${img.image}) center/cover no-repeat` }} />
                                    <CancelIcon sx={{fontSize: "20px", position: "relative", right: "20px", cursor: "pointer" }}
                                        onClick={() => handleDeleteImage(img.uuid)}
                                    /> 
                                </div>
                                ))
                            }
                            {images?.map((img, index) => (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <ImgDisplay key={index} sx={{ background: `url(${URL.createObjectURL(img)}) center/cover no-repeat` }} />
                                    <CancelIcon sx={{fontSize: "20px", position: "relative", right: "20px", cursor: "pointer" }}
                                        onClick={() => handleRemoveImage(index)}
                                    /> 
                                </div>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item md={12} sx={{ display: "flex", justifyContent: "end" }}>
                        <SaveBTN onClick={() => handlePayments()}>
                            {saving ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Save"
                            )}
                        </SaveBTN>
                    </Grid>
                </Grid>
            </SectionWrapper>
        </>
    )
}

const SectionWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '100px',
    height: '90px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    borderRadius: '10px',
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px',
    height: '40px',
    marginTop: "15px",
    fontSize: "16px",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default Payments