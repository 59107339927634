import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import axios from "axios";

// Loader Import
import { MoonLoader } from "react-spinners";

// MUI Components Import
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from "@mui/material";

// Components Import
import Header from "../../components/Header";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik & Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../../../constants/api";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

const validationSchema = Yup.object({
  engName: Yup.string().required("English Name is required"),
  arabicName: Yup.string().required("Arabic Name is required"),
});

const headerData = {
  heading: "Configure Subcategories",
  subheading: "",
};

function Subcategories() {
  const token = window.localStorage.getItem("user-token");

  const { loading, handleLoader } = useLoaderContext();

  const imgUpload = useRef();

  const selectFiles = () => {
    imgUpload.current.click();
  };

  const [saving, setSaving] = useState(false);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [catId, setCatId] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImg] = useState(null);
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [uuid, setUUID] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImg(null);
  };

  const formik = useFormik({
    enableReinitialze: true,
    initialValues: {
      engName: editingSubcategory != null ? editingSubcategory.name : "",
      arabicName: editingSubcategory != null ? editingSubcategory.arb_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editingSubcategory) {
        handleEdit(editingSubcategory.uuid, values, image);
      } else {
        handleCreate(values);
      }
    },
  });

  const editSubcategory = (subcat) => {
    formik.setFieldValue("engName", subcat.name);
    formik.setFieldValue("arabicName", subcat.arb_name);
    setImage(subcat.image);
    setPreviewImg(subcat.image);
    setEditingSubcategory(subcat);
    setCatId(subcat?.catId);
  };

  const handleCreate = (formValues) => {
    if (!image) {
      toast.error("Please upload an image.");
      return;
    }

    const formData = new FormData();

    formData.append("name", formValues.engName);
    formData.append("arb_name", formValues.arabicName);
    formData.append("category_sub", catId);
    if (typeof image !== "string") {
      formData.append("image", image);
    }

    setSaving(true);
    axios
      .post(`${baseUrl}subcategory/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Subcategory created successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
        setCatId("");
        setImage(null);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleEdit = (id, formValues, image) => {
    if (!image) {
      toast.error("Please upload an image.");
      return;
    }

    const formData = new FormData();

    formData.append("name", formValues.engName);
    formData.append("arb_name", formValues.arabicName);
    formData.append("category_sub", catId);
    if (typeof image !== "string") {
      formData.append("image", image);
    }

    setSaving(true);
    axios
      .put(`${baseUrl}subcategory/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Category edited successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
        setImage(null);
        setCatId("");
        setEditingSubcategory(null);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}subcategory/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success("Subcategory deleted successfully!");
        fetchEntities();
        setSubcategoriesList(response.data.brands);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const fetchEntities = async () => {
    handleLoader(true);
    axios
      .get(`${baseUrl}entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setCatList(response.data.categories);
        setSubcategoriesList(response.data.subcategories);
        handleLoader(false);
      })
      .catch((error) => {
        console.log("Error", error);
        handleLoader(false);
      });
  };

  useEffect(() => {
    fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openConfirmationModal = (id) => {
    setUUID(id);
    setConfirmationModal(true);
  };

  return (
    <>
      {uuid ? (
        <ConfirmationModal
          prodId={uuid}
          open={confirmationModal}
          setOpen={setConfirmationModal}
          heading={"Confirm Subcategory Deletion"}
          text={"Are you sure you want to delete the Subcategory?"}
          handleDeleteProduct={handleDelete}
        />
      ) : null}
      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <ControlWrapper mt={3}>
          <Heading>Create a Subcategory</Heading>
          <Grid
            container
            mt={1}
            spacing={3}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item md={3}>
              <Label>Subcategory Name (English)</Label>
              <Input
                name="engName"
                {...formik.getFieldProps("engName")}
                error={formik.touched.engName && Boolean(formik.errors.engName)}
                helperText={formik.touched.engName && formik.errors.engName}
              />
            </Grid>
            <Grid item md={3}>
              <Label>Subcategory Name (Arabic)</Label>
              <Input
                name="arabicName"
                {...formik.getFieldProps("arabicName")}
                error={
                  formik.touched.arabicName && Boolean(formik.errors.arabicName)
                }
                helperText={
                  formik.touched.arabicName && formik.errors.arabicName
                }
              />
            </Grid>
            <Grid item md={2}>
              <FormControl
                mt={3}
                size="small"
                sx={{ width: "100%", height: "56px", background: "#fff" }}
              >
                <Label>Subcategory to</Label>
                <Select
                  sx={{ width: "100%", height: "100%" }}
                  value={catId}
                  onChange={(e) => setCatId(e.target.value)}
                >
                  {catList?.map((item) => (
                    <MenuItem key={item.id} value={item.catId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} display={"flex"} alignItems={"center"}>
              <Uploader item md={3} onClick={() => selectFiles()}>
                <input
                  type="file"
                  accept="image/*"
                  ref={imgUpload}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageUpload(e)}
                  id="imageInput"
                />
                <AddPhotoIcon sx={{ fontSize: "25px" }} />
                <Typography
                  sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
                >
                  Add Image
                </Typography>
              </Uploader>
              <Grid item md={9}>
                {image ? (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <ImgDisplay
                      sx={{
                        background: `url(${previewImage}) center/cover no-repeat`,
                      }}
                    />
                    <CancelIcon
                      sx={{
                        fontSize: "20px",
                        position: "relative",
                        right: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => removeImage()}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid item md={2}>
              <SaveBTN onClick={() => formik.handleSubmit()}>
                {saving ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : editingSubcategory ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </SaveBTN>
            </Grid>
          </Grid>
        </ControlWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Image</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Category</TableHeadings>
                <TableHeadings sx={{ textAlign: "center" }}>
                  Action
                </TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableContent sx={{ width: "100%" }}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : subcategoriesList?.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={4} align="center">
                    You do not have any Subcategories yet!
                  </TableContent>
                </TableRow>
              ) : (
                subcategoriesList?.map((subcat, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>
                      {index + 1}
                    </TableContent>
                    <TableContent sx={{ width: "5%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <img
                          src={subcat?.image}
                          alt="subcategory_image"
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </TableContent>
                    <TableContent sx={{ width: "40%" }}>
                      {subcat.name} , {subcat.arb_name}
                    </TableContent>
                    <TableContent>{subcat.category_name}</TableContent>
                    <TableContent
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editSubcategory(subcat)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          onClick={() => openConfirmationModal(subcat?.uuid)}
                        />
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  float: "right",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Subcategories;
