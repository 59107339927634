import React, { useEffect, useState } from 'react'

import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography, styled } from '@mui/material';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';
import { baseUrl } from '../../../../../constants/api';
import { toast } from 'react-toastify';

function Integrations() {

    const token = window.localStorage.getItem("user-token");

    const [key, setKey] = useState('');
	const [saving, setSaving] = useState(false);

     // Fatoorah Payment Gateway States
     const [fatoorahToggle, setFatoorahToggle] = useState(false);
     const [fatoorahToken, setFatoorahToken] = useState('');
     const [fatoorahWebhookSecret, setFatoorahWebhookSecret] = useState('');
 
     // Payfort Payment Gateway States
     const [payfortToggle, setPayfortToggle] = useState(false);
     const [payfortMerchantID, setPayfortMerchantID] = useState('');
     const [payfortAccessCode, setPayfortAccessCode] = useState('');
     const [payfortCurrency, setPayfortCurrency] = useState('SAR');
     const [payfortLanguage, setPayfortLanguage] = useState('en');
     const [payfortSHARequestPhrase, setPayfortSHARequestPhrase] = useState('');

    //  OpenAI States
    const [model, setModel] = useState('')
	const [isOpenaiActive, setIsOpenaiActive] = useState(false);

    //  AyMakan Logistics Gateway States
    const [aymakanToggle, setAyMakanToggle] = useState(false);
    const [aymakanKey, setAymakanKey] = useState('');
	const [isAymakanActive, setIsAymakanActive] = useState(false);

     const handleFatoorahToggle = () => {
        setFatoorahToggle(!fatoorahToggle);
        if (!fatoorahToggle) {
            setPayfortToggle(false); // Disable Payfort if Fatoorah is enabled
        }
    };
    
    const handlePayfortToggle = () => {
        setPayfortToggle(!payfortToggle);
        if (!payfortToggle) {
            setFatoorahToggle(false); // Disable Fatoorah if Payfort is enabled
        }
    };

    const handleAymakanToggle = () => {
        setAyMakanToggle(!aymakanToggle);
    };

    const handleUpdate = async () => {
        // Validation for Fatoorah
        if (fatoorahToggle) {
            if (!fatoorahToken) {
                toast.error("All Fatoorah fields are required when enabled!");
                return;
            }
        }
    
        // Validation for Payfort
        if (payfortToggle) {
            if (!payfortMerchantID || !payfortAccessCode || !payfortCurrency || !payfortLanguage || !payfortSHARequestPhrase) {
                toast.error("All Payfort fields are required when enabled!");
                return;
            }
        }

        // Validation for OpenAI
        if (isOpenaiActive) {
            if (!key || !model) {
                toast.error("All OpenAI fields are required!");
                return;
            }
        }

        // Validation for AyMakan
        if (aymakanToggle) {
            if (!aymakanKey) {
                toast.error("All AyMakan fields are required when enabled!");
                return;
            }
        }
    
        setSaving(true);
    
        const payload = {
            is_fatoorah: fatoorahToggle,
            is_payfort: payfortToggle,
            is_aymakan: aymakanToggle,
            fatoorah: {
                token: fatoorahToken,
                webhook_secret: fatoorahWebhookSecret,
            },
            payfort: {
                merchant_id: payfortMerchantID,
                access_code: payfortAccessCode,
                currency: payfortCurrency,
                language: payfortLanguage,
                sha_request_phrase: payfortSHARequestPhrase,
            },
            openai: {
                apiKey: key,
                model: model,
                is_active: isOpenaiActive
            },
            aymakan: {
                apiKey: aymakanKey,
                is_active: isAymakanActive
            }
        };
    
        try {
            await axios.post(`${baseUrl}integration/update`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            toast.success("Settings updated successfully!");
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            setSaving(false);
        }
    };

    const fetchIntegrations = () => {
        axios.get(`${baseUrl}integration/update`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            const data = response.data;
    
            // Set Fatoorah fields
            setFatoorahToken(data?.fatoorah?.token || '');
            setFatoorahWebhookSecret(data?.fatoorah?.webhook_secret || '');
            setFatoorahToggle(Boolean(data?.is_fatoorah)); // Enable toggle if token exists

            // Set Payfort fields
            setPayfortMerchantID(data?.payfort?.merchant_id || '');
            setPayfortAccessCode(data?.payfort?.access_code || '');
            setPayfortCurrency(data?.payfort?.currency || 'SAR');
            setPayfortLanguage(data?.payfort?.language || 'en');
            setPayfortSHARequestPhrase(data?.payfort?.sha_request_phrase || '');
            setPayfortToggle(Boolean(data?.is_payfort)); // Enable toggle if merchant_id or access_code exists

            // Set OpenAI fields
            setKey(data?.openai?.apiKey || '');
            setModel(data?.openai?.model || '');
            setIsOpenaiActive(data?.openai?.is_active || false);

            // Set AyMakan fields
            setAyMakanToggle(Boolean(data?.is_aymakan)); // Enable toggle if token exists
            setAymakanKey(data?.aymakan.apiKey || '')
            setIsAymakanActive(data?.aymakan?.is_active || false);
        })
        .catch((err) => {
            console.log("Error", err);
        });
    }
    

    useEffect(() => {
        fetchIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SectionWrapper mt={3}>
                <Heading>Integrations</Heading>
                <Grid container mt={1} spacing={1} display={"flex"} justifyContent={"space-between"} gap={3}>
                    <IntegrationCard item md={5.7}  >
                        <CardHeader >
                            <Heading>Fatoorah Payment Gateway</Heading>
                            <Switch
                                checked={fatoorahToggle}
                                onChange={handleFatoorahToggle}
                            />
                        </CardHeader>
                        {fatoorahToggle && (
                            <ActiveCard item md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Token*</Label>
                                    <Input value={fatoorahToken} onChange={(e) => setFatoorahToken(e.target.value)} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Webhook Secret (optional)</Label>
                                    <Input value={fatoorahWebhookSecret} onChange={(e) => setFatoorahWebhookSecret(e.target.value)} />
                                </Box>
                            </ActiveCard>
                        )}
                    </IntegrationCard>
                    <IntegrationCard item md={5.7}  >
                        <CardHeader >
                            <Heading>Payfort Payment Gateway</Heading>
                            <Switch
                                checked={payfortToggle}
                                onChange={handlePayfortToggle}
                            />
                        </CardHeader>
                        {payfortToggle && (
                            <ActiveCard item md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Merchant Identifier</Label>
                                    <Input value={payfortMerchantID} onChange={(e) => setPayfortMerchantID(e.target.value)} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Access Code</Label>
                                    <Input value={payfortAccessCode} onChange={(e) => setPayfortAccessCode(e.target.value)} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Currency</Label>
                                    <Input value={payfortCurrency} onChange={(e) => setPayfortCurrency(e.target.value)} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>Language</Label>
                                    <Input value={payfortLanguage} onChange={(e) => setPayfortLanguage(e.target.value)} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Label>SHA Request Phrase</Label>
                                    <Input value={payfortSHARequestPhrase} onChange={(e) => setPayfortSHARequestPhrase(e.target.value)} />
                                </Box>
                            </ActiveCard>
                        )}
                    </IntegrationCard>
                    <IntegrationCard item md={12}>
                        <CardHeader >
                            <Heading>OpenAI Keys</Heading>
                            <Switch
                                checked={aymakanToggle}
                                onChange={handleAymakanToggle}
                            />
                        </CardHeader>
                        <Grid container mt={1} spacing={3} display={"flex"}>
                            <Grid item md={4}>
                                <Box
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Label>Add OpenAI Key</Label>
                                    <Input
                                        placeholder="e.g. 1234567890"
                                        value={key}
                                        onChange={(e) => setKey(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={2}>
                                <Box>
                                    <Label>Active</Label>
                                    <Checkbox
                                        type="checkbox"
                                        checked={isOpenaiActive}
                                        onChange={(e) => setIsOpenaiActive(e.target.checked)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Dropdown fullWidth>
                                    <InputLabel id="model-option-label">Model Options</InputLabel>
                                    <Select
                                        labelId="model-option-label"
                                        id="model-option-select"
                                        value={model}
                                        label="Model Options"
                                        onChange={(e) => setModel(e.target.value)}
                                    >
                                        <MenuItem value="gpt-3.5-turbo">GPT 3.5 Turbo</MenuItem>
                                        <MenuItem value="gpt-4o">GPT 4o</MenuItem>
                                        <MenuItem value="gpt-4o-mini">GPT 4o Mini</MenuItem>
                                    </Select>
                                </Dropdown>
                            </Grid>
                        </Grid>
                    </IntegrationCard>
                    <IntegrationCard item md={12}>
                        <CardHeader >
                            <Heading>AyMakan Logistics</Heading>
                        </CardHeader>
                        <Grid container mt={1} spacing={3} display={"flex"}>
                            <Grid item md={4}>
                                <Box
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Label>Add AyMakan API Key</Label>
                                    <Input
                                        placeholder="e.g. 1234567890"
                                        value={aymakanKey}
                                        onChange={(e) => setAymakanKey(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={2}>
                                <Box>
                                    <Label>Active</Label>
                                    <Checkbox
                                        type="checkbox"
                                        checked={isAymakanActive}
                                        onChange={(e) => setIsAymakanActive(e.target.checked)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </IntegrationCard>
                    <Grid container mt={1}>
                        <Grid item md={12} sx={{ margin: "20px 0"}}>
                            <SaveBTN onClick={() => handleUpdate()}>
                                {saving ? (
                                    <>
                                        <MoonLoader color="#fff" size={20} />
                                    </>
                                ) : (
                                    "Update"
                                )}
                            </SaveBTN>
                        </Grid>
                    </Grid>
                </Grid>
            </SectionWrapper>
        </>
    )
}

const SectionWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "17px",
    fontWeight: "700",
    color: "#424242",

}));

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const IntegrationCard = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
    padding: '10px 20px 10px 20px',
}))
const CardHeader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
}))

const ActiveCard = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
    gap: '8px'
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: "120px",
    height: "40px",
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

const Dropdown = styled(FormControl)(() => ({
	width: "100%",
	border: "1px solid #EFEFEF",
	marginTop: "5px",
	backgroundColor: "#fff",
	borderRadius: "6px",
}));

export default Integrations