import React from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";

const SelectInput = ({
  name,
  value,
  onChange,
  onBlur,
  error,
  label,
  options,
  children,
  onMenuItemClick,
  styles,
  fetching,
  data,
  disabled,
  any,
  variant,
  borderRadius,
  height,
  readOnly = false,
}) => {
  let labelName = label;

  return (
    <>
      <FormControl sx={styles}>
        <Select
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          error={!disabled && error}
          disabled={disabled}
          displayEmpty
          inputProps={{ "aria-label": "Without label", readOnly: readOnly }}
          sx={{
            fontWeight: "400",
            borderRadius: borderRadius || "5px",
            background: "#fff",
            height: height || "50px",
            border: "none",
            width: "100%",
            color: (theme) => theme.palette.text.primary,
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
            "& .Mui-disabled": {
              color: (theme) => theme.palette.text.disabled,
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-input": {
              cursor: readOnly ? "not-allowed" : "",
            },
          }}
        >
          <MenuItem value="">
            <Box sx={{ font: "inherit", color: "#b5b5bd", fontSize: "14px" }}>
              {labelName}
            </Box>
          </MenuItem>
          {any && (
            <MenuItem
              value="Any"
              onClick={
                onMenuItemClick
                  ? () => onMenuItemClick({ value: "Any", label: "Any" })
                  : undefined
              }
            >
              -- Any --
            </MenuItem>
          )}
          {fetching ? (
            "Loading..."
          ) : data?.length > 0 ? (
            options?.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                disabled={option.disabled}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={
                  onMenuItemClick ? () => onMenuItemClick(option) : undefined
                }
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Record Found</MenuItem>
          )}
        </Select>
        {!disabled && children}
      </FormControl>
    </>
  );
};

export default SelectInput;
