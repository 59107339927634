// React Imports
import React, { useEffect, useState } from "react";
// MUI
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { onKeyDown, thousandSeparator } from "../../../utils";
// Formik
import { Form, Formik } from "formik";
import { SubHeading } from "../../../components/Heading";
import { addServiceSchema } from "../validations/addServiceSchema";
// React Toastify
import { MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import Errors from "../../../components/Error";
import PrimaryInput from "../../../components/PrimaryInput/PrimaryInput";
import SelectInput from "../../../components/SelectInput";
import Loader from "../../../pages/Loaders/Loader";
import { useGetChargeTypesQuery } from "../../../redux/api/chargeTypeApiSlice";
import {
  useCreateServiceMutation,
  useGetServiceQuery,
  useUpdateServiceMutation,
} from "../../../redux/api/serviceApiSlice";
import { useGetServiceCategoriesQuery } from "../../../redux/api/serviceCategoryApiSlice";
import { useGetServiceTypesQuery } from "../../../redux/api/serviceTypeApiSlice";

const AddService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.pathname.split("/")[3];

  const [formValues, setFormValues] = useState({
    serviceName: "",
    serviceName_ar: "",
    rate: "",
    serviceType: [],
    description: "",
    description_ar: "",
    serviceTerm: "",
    serviceTerm_ar: "",
    category: "",
    chargeType: "",
  });

  // todo: GET SERVICE TYPE API CALL
  const { data: getServiceTypes, isLoading: getServiceTypesLoading } =
    useGetServiceTypesQuery({});

  // todo: GET CHARGE TYPE API CALL
  const { data: getChargeTypes, isLoading: getChargeTypeLoading } =
    useGetChargeTypesQuery({});

  // todo: GET SERVICE CATEGORY  API CALL
  const { data: getCategories, isLoading: getCategoryLoading } =
    useGetServiceCategoriesQuery({});

  // todo: 1) CREATE SERVICE API BIND
  const [createService, { isLoading }] = useCreateServiceMutation();

  // todo: 2) UPDATE SERVICE  API BIND
  const [updateService, { isLoading: updateServiceLoading }] =
    useUpdateServiceMutation();

  const AddDoctorHandler = async (data, resetForm) => {
    const serviceType = data.serviceType?.map((type) => type.id);

    const payload = {
      en_serviceName: data.serviceName,
      ar_serviceName: data.serviceName_ar,
      en_description: data.description,
      ar_description: data.description_ar,
      en_serviceTerms: data.serviceTerm,
      ar_serviceTerms: data.serviceTerm_ar,
      rate: data.rate.replace(/,/g, ""),
      serviceType,
      category: data.category?.id,
      chargeType: data.chargeType?.id,
    };

    // todo: UPDATE SERVICE
    if (id) {
      try {
        const update = await updateService({
          body: payload,
          id,
        });

        if (!update?.error) {
          toast.success("Service updated successfully!");
          resetForm();
          navigate("/dashboard/services");
        }
        if (update?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Update Service Error:", error);
        toast.error(error.response.data.message);
      }

      return;
    }

    try {
      const create = await createService(payload);

      if (!create?.error) {
        toast.success("Service created successfully!");
        resetForm();
        navigate("/dashboard/services");
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Create Service Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // todo: 3) GET SERVICE API CALL
  const { data: getService, isLoading: getServiceLoading } =
    useGetServiceQuery(id);

  useEffect(() => {
    if (id) {
      if (getService) {
        const category = getCategories?.find(
          (cat) => cat.id === getService?.category
        );
        const chargeType = getChargeTypes?.find(
          (charge) => charge.id === getService?.chargeType
        );

        const filteredServiceType = getServiceTypes?.filter((type) =>
          getService?.serviceType?.includes(type.id)
        );

        setFormValues({
          serviceName: getService?.en_serviceName,
          serviceName_ar: getService?.ar_serviceName,
          rate: thousandSeparator(Number(getService?.rate)),
          description: getService?.en_description,
          description_ar: getService?.ar_description,
          serviceTerm: getService?.en_serviceTerms,
          serviceTerm_ar: getService?.ar_serviceTerms,
          category: category,
          serviceType: filteredServiceType,
          chargeType: chargeType,
        });
      }
    }
  }, [id, getService, getCategories, getServiceTypes, getChargeTypes]);

  const handleRateChange = (e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Add commas to the numeric value
    const formattedValue = numericValue
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setFieldValue("rate", formattedValue);
  };

  return (
    <>
      {(getServiceTypesLoading ||
        getChargeTypeLoading ||
        getServiceLoading ||
        getCategoryLoading) && <Loader />}

      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Box>{id ? <h3>Update Service</h3> : <h3>Add a new Service</h3>}</Box>

        <Box
          sx={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            marginTop: "20px",
          }}
        >
          <Box>
            <Formik
              initialValues={formValues}
              onSubmit={(values, { resetForm }) => {
                AddDoctorHandler(values, resetForm);
              }}
              validationSchema={addServiceSchema}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                } = props;

                return (
                  <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Name (Eng)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="serviceName"
                          placeholder="Service Name"
                          value={values.serviceName}
                          helperText={
                            errors.serviceName && touched.serviceName
                              ? errors.serviceName
                              : ""
                          }
                          error={
                            errors.serviceName && touched.serviceName
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                        />
                      </Box>
                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Name (Arabic)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="serviceName_ar"
                          placeholder="Service Name (Arabic)"
                          value={values.serviceName_ar}
                          helperText={
                            errors.serviceName_ar && touched.serviceName_ar
                              ? errors.serviceName_ar
                              : ""
                          }
                          error={
                            errors.serviceName_ar && touched.serviceName_ar
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          height: "85px",
                          width: "100%",
                        }}
                      >
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Type
                        </SubHeading>
                        <CustomAutocomplete
                          label=""
                          name="serviceType"
                          options={getServiceTypes}
                          formik={props}
                          placeholder="Service Type"
                          borderRadius={3}
                          labelKey="en_service_name"
                        />
                      </Box>
                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Charge Type
                        </SubHeading>
                        <SelectInput
                          name="chargeType"
                          styles={{ width: "100%" }}
                          value={values.chargeType || ""}
                          borderRadius={3}
                          onChange={(e) => {
                            props.handleChange(e);
                          }}
                          data={getChargeTypes}
                          onBlur={handleBlur}
                          error={
                            errors.chargeType && touched.chargeType
                              ? true
                              : false
                          }
                          fetching={getChargeTypeLoading}
                          label="Charge Type"
                          options={getChargeTypes?.map((charge) => {
                            return {
                              ...charge,
                              id: charge.id,
                              value: charge,
                              label: `${charge.en_charge_name} - ${charge.ar_charge_name}`,
                            };
                          })}
                        >
                          {errors.chargeType && (
                            <Errors sx={{ paddingLeft: "0" }}>
                              {errors.chargeType && touched.chargeType
                                ? errors.chargeType
                                : ""}
                            </Errors>
                          )}
                        </SelectInput>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Categories
                        </SubHeading>
                        <SelectInput
                          name="category"
                          styles={{ width: "100%" }}
                          value={values.category || ""}
                          borderRadius={3}
                          onChange={(e) => {
                            props.handleChange(e);
                          }}
                          data={getCategories}
                          onBlur={handleBlur}
                          error={
                            errors.category && touched.category ? true : false
                          }
                          fetching={getCategoryLoading}
                          label="Service Categories"
                          options={getCategories?.map((category) => {
                            return {
                              ...category,
                              id: category.id,
                              value: category,
                              label: `${category.name} - ${category.arb_name}`,
                            };
                          })}
                        >
                          {errors.category && (
                            <Errors sx={{ paddingLeft: "0" }}>
                              {errors.category && touched.category
                                ? errors.category
                                : ""}
                            </Errors>
                          )}
                        </SelectInput>
                      </Box>

                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Rate
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="rate"
                          placeholder="Rate"
                          value={values.rate}
                          helperText={
                            errors.rate && touched.rate ? errors.rate : ""
                          }
                          error={errors.rate && touched.rate ? true : false}
                          // onChange={handleChange}
                          onChange={(e) => handleRateChange(e, setFieldValue)}
                          onBlur={handleBlur}
                          borderRadius={3}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Description (Eng)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="description"
                          placeholder="Description"
                          value={values.description}
                          helperText={
                            errors.description && touched.description
                              ? errors.description
                              : ""
                          }
                          error={
                            errors.description && touched.description
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                          multiline={true}
                          maxRows={2}
                        />
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Description (Arabic)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="description_ar"
                          placeholder="Description (Arabic)"
                          dir="rtl"
                          value={values.description_ar}
                          helperText={
                            errors.description_ar && touched.description_ar
                              ? errors.description_ar
                              : ""
                          }
                          error={
                            errors.description_ar && touched.description_ar
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                          multiline={true}
                          maxRows={2}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Term (Eng)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="serviceTerm"
                          placeholder="Service Term"
                          value={values.serviceTerm}
                          helperText={
                            errors.serviceTerm && touched.serviceTerm
                              ? errors.serviceTerm
                              : ""
                          }
                          error={
                            errors.serviceTerm && touched.serviceTerm
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                          multiline={true}
                          maxRows={2}
                        />
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Service Term (Arabic)
                        </SubHeading>
                        <PrimaryInput
                          type="text"
                          label=""
                          name="serviceTerm_ar"
                          placeholder="Service Term (Arabic)"
                          dir="rtl"
                          value={values.serviceTerm_ar}
                          helperText={
                            errors.serviceTerm_ar && touched.serviceTerm_ar
                              ? errors.serviceTerm_ar
                              : ""
                          }
                          error={
                            errors.serviceTerm_ar && touched.serviceTerm_ar
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          borderRadius={3}
                          multiline={true}
                          maxRows={2}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "15px",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                          height: "40px",
                          width: "150px",
                          mt: "23px",
                          borderRadius: "5px",
                        }}
                        onClick={() => navigate("/dashboard/services")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{
                          height: "40px",
                          width: "150px",
                          mt: "23px",
                          borderRadius: "5px",
                        }}
                      >
                        {isLoading || updateServiceLoading ? (
                          <>
                            <MoonLoader color="#fff" size={20} />
                          </>
                        ) : id ? (
                          "Update"
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddService;
