import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { useHotSellingProductsQuery } from "../../../../redux/api/utilApiSlice";
import { thousandSeparator } from "../../../../utils";
import { CSVLink } from "react-csv";

const tableHead = [
  "Image",
  "Name",
  "Category",
  "Sub Category",
  "Brand",
  "Origin",
  "Stock",
  "Price",
];

const HotSellingProductTable = () => {
  // todo: GET CHARGE TYPE API CALL
  const { data, isLoading } = useHotSellingProductsQuery({});

  const csvData = [
    ["Name", "Category", "Sub Category", "Brand", "Origin", "Stock", "Price"],
    ...(data
      ? data.map(
          ({
            commons,
            productCategory,
            productSubcategory,
            brand,
            origin,
            stock,
            discounted_price,
          }) => [
            commons?.en?.productName,
            productCategory,
            productSubcategory,
            brand,
            origin,
            stock?.productQuantity ? stock.productQuantity : "Out of Stock",
            discounted_price,
          ]
        )
      : []),
  ];

  return (
    <>
      <Card item md={12.0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CardHeading>Hot Selling Products</CardHeading>

          {/* <CSVLink
            className="downloadbtn"
            filename="my-file.csv"
            data={csvData}
          >
            Export to CSV
          </CSVLink> */}
        </Box>
        <Box
          sx={{
            margin: "10px 0",
          }}
        >
          <MUITable tableHead={tableHead}>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "70px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <MoonLoader color="#000" size={20} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : data?.length > 0 ? (
              data?.map((item) => {
                return (
                  <StyledTableRow key={item?.id}>
                    <StyledTableCell>
                      <Box>
                        <img
                          src={item?.images[0]?.image}
                          alt="product"
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.commons?.en?.productName}
                      <br />
                      {item?.commons?.ar?.productName}
                    </StyledTableCell>

                    <StyledTableCell>{item?.productCategory}</StyledTableCell>
                    <StyledTableCell>
                      {item?.productSubcategory}
                    </StyledTableCell>
                    <StyledTableCell>{item?.brand}</StyledTableCell>
                    <StyledTableCell>{item?.origin}</StyledTableCell>
                    <StyledTableCell>
                      {item?.stock?.productQuantity ? (
                        thousandSeparator(Number(item?.stock?.productQuantity))
                      ) : (
                        <Box
                          sx={{
                            fontSize: "13px",
                            color: "red",
                            fontWeight: "600",
                          }}
                        >
                          Out of Stock
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {thousandSeparator(Number(item?.discounted_price))}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {data?.length === 0 ? "No records found" : ""}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              justifyContent: "end",
              margin: "20px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#49464f",
                }}
              >
                Total Records: {data?.length}
              </Box>
              {/* <Pagination
                count={Math.ceil(services?.total_count / rowsPerPage)}
                shape="rounded"
                onChange={handleChangePage}
              /> */}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "15px 25px",
  borderRadius: "14px",
  border: "1px solid #ddd",
}));

const CardHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
}));

export default HotSellingProductTable;
