import React from 'react'
import '../../index.css'

function Loader() {
    return (
        <div className="loader">
            <div className="loading-spinner"></div>
            <span>Please wait...</span>
        </div>
    )
}

export default Loader