import React from "react";
import { Box } from "@mui/material";

const root = {
  fontSize: "25px",
  fontWeight: "700",
  whiteSpace: "wrap !important",
  color: "#000",

  "@media screen and (max-width: 425px)": {
    fontSize: "18px",
  },

  "@media screen and (max-width: 375px)": {
    fontSize: "15px",
  },
};

const subRoot = {
  fontSize: "15px",
  fontWeight: "500",
  whiteSpace: "wrap !important",
  color: "#000",

  "@media screen and (max-width: 425px)": {
    fontSize: "12px",
  },

  "@media screen and (max-width: 320px)": {
    fontSize: "12px",
  },
};

const Heading = (props) => {
  const styles = props.sx || {};
  return <Box sx={{ ...root, ...styles }}>{props.children}</Box>;
};

const SubHeading = (props) => {
  const styles = props.sx || {};
  return <Box sx={{ ...subRoot, ...styles }}>{props.children}</Box>;
};

export { Heading, SubHeading };
