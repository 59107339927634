import { apiSlice } from "./apiSlice";

export const footerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFooterSections: builder.query({
      query: () => {
        return {
          url: "footer/sections",
          method: "GET",
        };
      },
      providesTags: ["Footer Sections"],
    }),
    updateFooterSection: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: "footer/sections",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Footer Sections"],
    }),
  }),
});

export const { useGetFooterSectionsQuery, useUpdateFooterSectionMutation } =
  footerApiSlice;
