import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

// Recharts Components Import
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { baseUrl } from "../../../../constants/api";

// React Toastify Imports
import { MoonLoader } from "react-spinners";

import ExpiringProductTable from "./ExpiringProductTable";
import HotSellingProductTable from "./HotSellingProductTable";

function ReportCards() {
  const token = window.localStorage.getItem("user-token");

  const [loading, isLoading] = useState(false);
  const [outofstock, setOutofstock] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [topProducts, setTopProducts] = useState([]);

  const fetchOutofStockProducts = () => {
    isLoading(true);
    axios
      .get(`${baseUrl}products/outofstock`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setOutofstock(response.data);
        isLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        isLoading(false);
      });
  };

  const fetchSalesData = () => {
    isLoading(true);
    axios
      .get(`${baseUrl}products/sales`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setSalesData(response.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const fetchTopProducts = () => {
    isLoading(true);
    axios
      .get(`${baseUrl}products/toprated`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setTopProducts(response.data);
        isLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchOutofStockProducts();
    fetchSalesData();
    fetchTopProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        my={3}
        sx={{ display: "flex", justifyContent: "start", gap: "20px" }}
      >
        <Card item md={12}>
          <CardHeading>Sales Report (Last 7 Days)</CardHeading>
          <Box sx={{ height: "200px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={salesData} width={250} height={40}>
                <XAxis dataKey="name" tick={{ fontSize: "8px" }} />
                <YAxis tick={{ fontSize: "8px" }} />
                <Bar barSize={50} dataKey="counts" fill="#009444" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Card>

        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Card item md={5.9}>
            <CardHeading>Out of Stock Report</CardHeading>
            <TableWrapper component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "#DDDDDD" }}>
                  <TableRow>
                    <TableHeadings>#</TableHeadings>
                    <TableHeadings>Name</TableHeadings>
                    <TableHeadings>Category</TableHeadings>
                    <TableHeadings>Quantity</TableHeadings>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow align="center">
                      <TableContent colSpan={4}>
                        <MoonLoader color="#000" size={20} />
                      </TableContent>
                    </TableRow>
                  ) : outofstock.length === 0 ? (
                    <TableRow align="center">
                      <TableContent colSpan={4} align="center">
                        No data to show
                      </TableContent>
                    </TableRow>
                  ) : (
                    outofstock.map((product, index) => (
                      <TableRow key={index}>
                        <TableContent>{product?.skU}</TableContent>
                        <TableContent>
                          {product?.commons?.en?.productName} -{" "}
                          {product?.commons?.ar?.productName}
                        </TableContent>
                        <TableContent>{product?.productCategory}</TableContent>
                        <TableContent>
                          {product?.available_quantity}
                        </TableContent>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
          </Card>

          <Card item md={5.9}>
            <CardHeading>Top Rated Products</CardHeading>
            <TableWrapper component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "#DDDDDD" }}>
                  <TableRow>
                    <TableHeadings>#</TableHeadings>
                    <TableHeadings>Name</TableHeadings>
                    <TableHeadings>Category</TableHeadings>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow align="center">
                      <TableContent colSpan={4}>
                        <MoonLoader color="#000" size={20} />
                      </TableContent>
                    </TableRow>
                  ) : topProducts.length === 0 ? (
                    <TableRow align="center">
                      <TableContent colSpan={4} align="center">
                        No data to show
                      </TableContent>
                    </TableRow>
                  ) : (
                    topProducts.map((product, index) => (
                      <TableRow key={index}>
                        <TableContent>{product?.skU}</TableContent>
                        <TableContent>
                          {product?.commons?.en?.productName} -{" "}
                          {product?.commons?.ar?.productName}
                        </TableContent>
                        <TableContent>{product?.productCategory}</TableContent>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
          </Card>
        </Grid>
        <HotSellingProductTable />

        <ExpiringProductTable />
      </Grid>
    </>
  );
}

// Styled Components
const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "15px 25px",
  borderRadius: "14px",
  border: "1px solid #ddd",
}));

const CardHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default ReportCards;
