import React from 'react'

import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import NotifCard from './NotifCard';


function NotificationTooltip() {
    return (
        <>
            <TooltipWrapper>
                <Typography sx={{ fontSize: '18px', fontWeight: '800', color: '#000' }}>
                    Notifications
                </Typography>
                <NotifCard />
                <NotifCard />

            </TooltipWrapper>
        </>
    )
}

// Styled Components
const TooltipWrapper = styled(Box)(({ theme }) => ({
    width: 'auto',
    padding: '10px',
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    backgroundColor: '#fff',
    width: '100%',
    gap: '10px'
}));

export default NotificationTooltip