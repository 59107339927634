import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const CustomDatePicker = ({ value, onChange, minDate, maxDate, sx }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
      <DatePicker
        format="YYYY-MM-DD"
        valueFormat="YYYY-MM-DD"
        value={dayjs(value)}
        onChange={onChange}
        slotProps={{
          textField: { size: "small", error: false },
        }}
        sx={{ background: "#fff", ...sx }}
        minDate={minDate ? dayjs(new Date()) : null}
        maxDate={maxDate ? dayjs(new Date()) : null}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
