import React from "react";
import { styled } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";

// MUI Components Import
import { Container } from "@mui/material";

// Components Import
import Dashboard from "./Dashboard";
import Navbar from "../components/Navbar";

function WareHouse() {
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();

  return (
    <>
      <Wrapper maxWidth={false}>
        <Navbar />
        {current_loc === "warehouse" ? <Dashboard /> : <Outlet />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  padding: "0 !important",
  width: "100%",
  height: "auto",
  overflow: "auto",
}));

export default WareHouse;
