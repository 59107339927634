import { apiSlice } from "./apiSlice";

export const serviceCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceCategories: builder.query({
      query: () => {
        return {
          url: "service-categories",
          method: "GET",
        };
      },
      providesTags: ["ServiceCategories"],
    }),

    deleteServiceCategory: builder.mutation({
      query: (id) => {
        return {
          url: `service-category/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ServiceCategories"],
    }),
  }),
});

export const {
  useGetServiceCategoriesQuery,
  useDeleteServiceCategoryMutation,
} = serviceCategoryApiSlice;
