import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
// MUI Components Import
import { Box, Modal, Typography, TextField, Grid, Button } from "@mui/material";
// Tags input component
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Icons Import
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import Loader from "../../../../Loaders/Loader";
import { useGetVariantsQuery } from "../../../../../redux/api/variantsApiSlice";
import axios from "axios";
import { baseUrl } from "../../../../../constants/api";
import { useGetSingleProductVariantsQuery } from "../../../../../redux/api/productsApiSlice";
import { useLoaderContext } from "../../../../../contexts/LoaderContext";
import { MoonLoader } from "react-spinners";
import { thousandSeparator } from "../../../../../utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertMessage from "../../../../../components/AlertMessage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  minHeight: "320px",
  maxHeight: "80%",
  backgroundColor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflowY: "scroll",
};

function VariantsModal(props) {
  const token = localStorage.getItem("user-token");
  const { loading, handleLoader } = useLoaderContext();

  const { open, setOpen } = props;
  // console.log("props", props?.singleProduct);
  const [variantsList, setVariantsList] = useState([]);
  const [variantTags, setVariantTags] = useState({});
  const [tagsVariants, setTagsVariants] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  // Define an array to hold refs for image uploads
  const imgUploadRefs = useRef([]);
  // Ensure imgUploadRefs is initialized with the correct length
  useEffect(() => {
    imgUploadRefs.current = Array(tagsVariants.length)
      .fill()
      .map((_, i) => imgUploadRefs.current[i] || createRef());
  }, [tagsVariants.length]);

  // todo: GET VARIANTS API BIND
  const { data, isLoading } = useGetVariantsQuery({});

  useEffect(() => {
    if (data) {
      setVariantsList(data);
    }
  }, [data]);

  const getImageSource = (image) => {
    if (typeof image === "string" && image.startsWith("https://")) {
      return image;
    }

    if (image instanceof File || image instanceof Blob) {
      return URL.createObjectURL(image);
    }
  };

  function getNamesAsString(arr) {
    return arr.map((item) => item.name).join(", ");
  }

  const showVariantsValues = (variant) => {
    const values = Object.values(variant);
    return values.join(" / ");
  };

  const handleChange = useCallback(
    (e, index, fieldName) => {
      const value = fieldName === "image" ? e.target.files[0] : e.target.value;
      const updatedData = [...tagsData];

      updatedData[index] = {
        ...updatedData[index],
        [fieldName]: value,
        variants: tagsVariants[index],
        combination: showVariantsValues(tagsVariants[index]),
      };

      setTagsData(updatedData);
    },
    [tagsData, tagsVariants]
  );

  const modalClose = () => {
    setOpen(false);
    setTagsVariants([]);
    setTagsData([]);
  };

  const handleDynamicChange = (newTags, variantName) => {
    // Update the state with the new tags for the corresponding variant
    setVariantTags((prevTags) => ({
      ...prevTags,
      [variantName]: newTags,
    }));
  };

  const addVariantsHandle = () => {
    const newVariant = variantTags;

    // delete key which length is 0
    Object.keys(newVariant).forEach((key) => {
      if (newVariant[key].length === 0) {
        delete newVariant[key];
      }
    });

    // if newVariant is emptyObject then return
    if (Object.keys(newVariant).length === 0) {
      toast.warning("Please add some values to create a variant!");
      return;
    }

    // Generate all possible combinations of tags for the new variant
    const combinations = Object.keys(newVariant).reduce(
      (acc, key) => {
        const values = newVariant[key];
        const newCombinations = values.map((value) => ({ [key]: value }));
        return acc.flatMap((existingCombination) =>
          newCombinations.map((newCombination) => ({
            ...existingCombination,
            ...newCombination,
          }))
        );
      },
      [{}]
    );

    const isAlreadyAdded = tagsVariants.some((value) => {
      return JSON.stringify(value) === JSON.stringify(combinations[0]);
    });

    if (isAlreadyAdded) {
      toast.warning(
        `This combination is already added! ${showVariantsValues(
          combinations[0]
        )}`
      );
      return;
    }

    // Add new combinations to tagsVariants
    setTagsVariants((oldVariants) => [...oldVariants, ...combinations]);
    setVariantTags({});
  };

  // todo: GET SINGLE PRODUCT VARIANTS API BIND
  const {
    data: singleProdVariantData,
    isLoading: singleProductVarLoading,
    refetch: refetchSingleProductVariant,
  } = useGetSingleProductVariantsQuery(props?.singleProduct?.prodId);

  const extractVariantData = (variantData) => {
    if (!variantData) return [];

    return variantData.map((variant) => ({
      barcode: variant.variantBarcode,
      combination: variant.variantCombination,
      sellingPrice: variant.variantPrice,
      quantity: variant.variantQuantity,
      image: variant.variantImage,
      uuid: variant.uuid,
    }));
  };

  const extractTagsVariants = (variantData) => {
    if (!variantData) return [];

    return variantData.map((variant) => variant.variants);
  };

  useEffect(() => {
    if (singleProdVariantData) {
      const tagsData = extractVariantData(singleProdVariantData);
      const tagsVariants = extractTagsVariants(singleProdVariantData);

      setTagsData(tagsData);
      setTagsVariants(tagsVariants);
    }
  }, [singleProdVariantData, open]);

  // console.log("tagsData", tagsData);
  // console.log("tagsVariants", tagsVariants);

  const totalQuantity = props?.singleProduct?.total_quantity;

  const totalQuantitySum = tagsData.reduce((acc, data) => {
    return acc + Number(data.quantity);
  }, 0);

  // todo: CREATE VARIANTS API BIND
  const saveVariants = async () => {
    // 1st check tagsData.quantity sum should not be greater than totalQuantity
    if (totalQuantitySum > totalQuantity) {
      toast.warning(
        `Total quantity sum should not be greater than ${thousandSeparator(
          Number(totalQuantity)
        )}!`
      );
      return;
    }

    // 2nd check tagsData length and tagsVariants length
    if (tagsData.length !== tagsVariants.length) {
      toast.warning("Please fill Selling Price and Quantity for all variants!");
      return;
    }

    // 3rd sellingPrice and quantity validation
    const isInvalid = tagsData.some((data) => {
      return !data?.sellingPrice || !data?.quantity;
    });

    if (isInvalid) {
      toast.warning("Please fill Selling Price and Quantity for all variants!");
      return;
    }

    // 4th create FormData
    const formData = new FormData();

    tagsData.forEach((data, index) => {
      formData.append(`data[${index}][barcode]`, data.barcode || "");
      formData.append(`data[${index}][combination]`, data.combination || "");
      formData.append(`data[${index}][sellingPrice]`, data.sellingPrice || "");
      formData.append(`data[${index}][quantity]`, data.quantity || "");
      formData.append(`data[${index}][image]`, data.image || "");
      formData.append(
        `data[${index}][variants]`,
        JSON.stringify(tagsVariants[index] || "")
      );
      // add uuid in edit mode
      formData.append(`data[${index}][uuid]`, tagsData[index]?.uuid || "");
    });

    const url = singleProdVariantData?.length > 0 ? "update" : "create";
    try {
      handleLoader(true);
      const response = await axios.post(
        `${baseUrl}product/variant/${url}/${props?.singleProduct?.prodId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.status === 201) {
        toast.success(
          `Variants ${
            singleProdVariantData?.length > 0 ? "update" : "added"
          } successfully!`
        );
        handleLoader(false);
        refetchSingleProductVariant();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        handleLoader(false);
      }
    }
  };

  const labelNames = getNamesAsString(variantsList);

  return (
    <>
      {(isLoading || singleProductVarLoading) && <Loader />}
      <Modal open={open} onClose={modalClose}>
        <Box sx={style}>
          {!isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <h4>{props?.singleProduct?.commons?.en?.productName}</h4>
                <h4>{props?.singleProduct?.commons?.ar?.productName}</h4>
              </Box>
              <Box>
                <h5>Products Quantity</h5>
                <Box>{thousandSeparator(Number(totalQuantity))}</Box>
              </Box>
            </Box>
          )}

          {variantsList?.length > 0 &&
            variantsList?.map((variant) => {
              return (
                <Box
                  sx={{
                    margin: "20px 0 30px 0",
                  }}
                  key={variant.variantId}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        width: "200px",
                        height: "fit-content",
                        padding: "8px 32px 8px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      {variant?.name}
                    </Box>
                    <Box
                      sx={{
                        width: "400px",
                      }}
                    >
                      <TagsInput
                        value={variantTags[variant.name] || []}
                        onChange={(newTags) =>
                          handleDynamicChange(newTags, variant.name)
                        }
                        inputProps={{
                          placeholder: `Enter ${variant.name} and press Enter...`,
                          style: { width: "100%" },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}

          <Box
            sx={{
              height: "fit-content",
              display: "flex",
              justifyContent: "flex-end",
              width: "55%",
              marginBottom: "30px",
            }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={addVariantsHandle}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Add Variants
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {tagsVariants?.length > 0 && (
              <AlertMessage
                sx={{
                  border: "1px solid #2a9d8f",
                  background: "#dbe8e6",
                }}
              >
                <span style={{ marginTop: "5px", marginRight: "3px" }}>
                  <LightbulbIcon
                    sx={{
                      color: "#2a9d8f",
                      fontSize: "15px",
                    }}
                  />
                </span>
                Automatically{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    margin: "0 4px",
                  }}
                >
                  {" "}
                  create {tagsVariants?.length}
                </span>{" "}
                variants based on the option defined above ({labelNames})
              </AlertMessage>
            )}

            {totalQuantitySum > totalQuantity && (
              <AlertMessage
                sx={{
                  border: "1px solid #427ca9",
                  background: "#cce8f4",
                }}
              >
                <span style={{ marginTop: "5px", marginRight: "3px" }}>
                  <ErrorOutlineIcon
                    sx={{
                      color: "#427ca9",
                      fontSize: "15px",
                    }}
                  />
                </span>
                {`The total sum of quantities (${totalQuantitySum}) must be lower than ${thousandSeparator(
                  Number(totalQuantity)
                )}`}
              </AlertMessage>
            )}
          </Box>

          {tagsVariants?.map((variant, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "30px",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <Box>{`${index + 1})`}</Box>
                <Box
                  sx={{
                    width: "160px",
                    height: "fit-content",
                    padding: "8px 32px 8px 10px",
                    borderRadius: "5px",
                  }}
                >
                  {showVariantsValues(variant)}
                </Box>

                <Box
                  sx={{
                    width: "220px",
                  }}
                >
                  <Label htmlFor={`barcode-${index}`}>Bar Code:</Label>
                  <Input
                    mt={3}
                    type="text"
                    size="small"
                    color="success"
                    variant="outlined"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    value={tagsData[index]?.barcode || ""}
                    onChange={(e) => handleChange(e, index, "barcode")}
                  />
                </Box>
                <Box
                  sx={{
                    width: "150px",
                  }}
                >
                  <Label htmlFor={`selling-price-${index}`}>
                    Selling Price:
                  </Label>
                  <Input
                    mt={3}
                    type="text"
                    size="small"
                    color="success"
                    variant="outlined"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    value={tagsData[index]?.sellingPrice || ""}
                    onChange={(e) => handleChange(e, index, "sellingPrice")}
                  />
                </Box>

                <Box
                  sx={{
                    width: "150px",
                  }}
                >
                  <Label htmlFor={`quantity-${index}`}>Quantity:</Label>
                  <Input
                    mt={3}
                    type="text"
                    size="small"
                    color="success"
                    variant="outlined"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    value={tagsData[index]?.quantity || ""}
                    onChange={(e) => handleChange(e, index, "quantity")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                    minWidth: "180px",
                  }}
                >
                  <Box>
                    <Uploader
                      item
                      md={1}
                      onClick={() => imgUploadRefs.current[index]?.click()}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref={(ref) => (imgUploadRefs.current[index] = ref)}
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e, index, "image")}
                      />
                      <AddPhotoIcon sx={{ fontSize: "25px" }} />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#2A3F54",
                        }}
                      >
                        Add Image
                      </Typography>
                    </Uploader>
                  </Box>

                  <Box>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tagsData[index]?.image && (
                        <Box
                          sx={{
                            width: "70px",
                          }}
                        >
                          <img
                            src={`${getImageSource(tagsData[index]?.image)}`}
                            alt={tagsData[index]?.image?.name}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Box>
                      )}

                      <CancelIcon
                        sx={{
                          fontSize: "20px",
                          position: "relative",
                          right: "20px",
                          cursor: "pointer",
                          display: tagsData[index]?.image ? "block" : "none",
                        }}
                        onClick={() => {
                          if (index >= 0 && index < tagsData.length) {
                            if (tagsData[index]?.image) {
                              setTagsData((oldData) => {
                                oldData[index].image = null;
                                return [...oldData];
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                <DeleteIcon
                  sx={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => {
                    setTagsVariants((oldVariants) =>
                      oldVariants.filter((_, i) => i !== index)
                    );
                    setTagsData((oldData) =>
                      oldData.filter((_, i) => i !== index)
                    );
                  }}
                />
              </Box>
            );
          })}

          {tagsVariants?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{
                  textTransform: "capitalize",
                  width: "140px",
                }}
                onClick={saveVariants}
              >
                {loading ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : (
                  <>
                    {singleProdVariantData?.length > 0
                      ? "Update Variants"
                      : "Save Variants"}
                  </>
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}

// Styled Components
const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
});

const Input = styled(TextField)({
  marginTop: "5px",
  background: "#fff",
  borderRadius: "12px",
  width: "100%",
});

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

export default VariantsModal;
