import React from 'react'
import { styled } from "@mui/material/styles";
import ContentLoader from "react-content-loader"

// MUI Components Import
import { Grid, Box, Typography, CardMedia } from '@mui/material'

const CardLoader = (props) => (
    <ContentLoader 
      speed={2}
      width={380}
      height={180}
      viewBox="0 0 380 180"
      backgroundColor="#dedede"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="38" y="22" rx="10" ry="10" width="320" height="150" />
    </ContentLoader>
)

function DashboardCards({ data }) {
    return (
        <>
            <Grid container my={3} sx={{display: "flex", justifyContent: "space-between"}}>
                {data.length === 0 ? 
                    <>
                        <CardLoader />
                        <CardLoader />
                        <CardLoader />
                    </>
                    : 
                    data.map((item, index) => (
                        <Card key={index} item md={3.9}>
                            <IconBox sx={{ background: item.backgroundColor }}>
                                <Icon component='img' image={item.icon} />
                            </IconBox>
                            <TextBox>
                                <Text>{item.heading}</Text>
                                <Number>{item.number}</Number>
                            </TextBox>
                        </Card>
                    ))
                }
            </Grid>
        </>
    )
}

// Styled Components

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "15px 20px",
    borderRadius: "14px",
    border: "1px solid #DDD",
}));

const IconBox = styled(Box)(({ theme }) => ({
    width: "44px", 
    height: "44px", 
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const Icon = styled(CardMedia)(() => ({
    width: "22px",
    height: "22px",
}))

const TextBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    margin: "10px 0" 
}))

const Text = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "500",
    color: "#001733",
    textAlign: "left"
}))

const Number = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#25282C",
    textAlign: "end"
}))

export default DashboardCards