import React, { useEffect, useState } from 'react';
import axios from 'axios';

// MUI Components Import
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, styled } from '@mui/material';

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Icons Import
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/BorderColor';
import { baseUrl } from '../../../../../../constants/api';

function Links() {

	const token = window.localStorage.getItem("user-token");

	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false);
	const [updating, setUpdating] = useState(false)
	const [engHeading, setEngHeading] = useState("")
	const [arbHeading, setArbHeading] = useState("")
	const [url, setUrl] = useState("")
	const [linkId, setLinkId] = useState('')
	const [links, setLinks] = useState([]);

	const fetchLinksData = () => {
		setLoading(true)
		axios.get(`${baseUrl}footer/link`)
			.then((response) => {
				setLinks(response.data)
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
			});
	};

	const handleLink = () => {
		const requiredFields = [
			{ value: engHeading, message: "English heading is required." },
			{ value: arbHeading, message: "Arabic heading is required." },
			{ value: url, message: "URL is required." },
		];
		for (const field of requiredFields) {
			if (!field.value) {
				toast.error(field.message);
				return;
			}
		}
		setSaving(true);
		const requestData = {
			eng_heading: engHeading,
			arb_heading: arbHeading,
			link: url,
		};
		const requestConfig = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${token}`,
			},
		};
		const response = !updating
			? axios.post(`${baseUrl}footer/link/create`, requestData, requestConfig)
			: axios.put(`${baseUrl}footer/link/${linkId}`, requestData, requestConfig);
		response
			.then(() => {
				toast.success("Links added successfully!");
				setEngHeading("");
				setArbHeading("");
				setUrl("");
				fetchLinksData();
				setSaving(false);
				setUpdating(false);
			})
			.catch((err) => {
				if (err.response) {
					toast.error(err.response.data.link[0] || "An error occurred");
				} else {
					toast.error("An error occurred");
				}
				setSaving(false);
			});
	};

	const editLink = (link) => {
		setUpdating(true)
		setEngHeading(link.eng_heading)
		setArbHeading(link.arb_heading)
		setUrl(link.link)
		setLinkId(link.uuid)
	}

	const handleDelete = (id) => {
		axios.delete(`${baseUrl}footer/link/${id}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			}
		)
			.then(() => {
				toast.success("Link deleted successfully!");
				fetchLinksData()
			})
			.catch((error) => {
				if (error.response) {
					toast.error(error.response.data.message);
				}
			})
	}

	useEffect(() => {
		fetchLinksData()
	}, [])

	return (
		<>
			<SectionWrapper mt={3}>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<Heading>Useful Links</Heading>
				</Box>
				<Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"}>
					<Grid item md={3.5}>
						<Label>Link Heading (English)</Label>
						<Input mt={3} type="text" color='success'
							InputProps={{ sx: { borderRadius: 3 } }}
							value={engHeading} onChange={(e) => setEngHeading(e.target.value)}
						/>
					</Grid>
					<Grid item md={3.5}>
						<Label>Link Heading (Arabic)</Label>
						<Input mt={3} type="text" color='success'
							InputProps={{ sx: { borderRadius: 3 } }}
							value={arbHeading} onChange={(e) => setArbHeading(e.target.value)}
						/>
					</Grid>
					<Grid item md={3.5}>
						<Label>Link URL</Label>
						<Input mt={3} type="text" color='success'
							InputProps={{ sx: { borderRadius: 3 } }}
							value={url} onChange={(e) => setUrl(e.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid item md={11} sx={{ display: "flex", justifyContent: "end" }}>
					<SaveBTN onClick={() => handleLink()}>
						{saving ? (
							<>
								<MoonLoader color="#fff" size={20} />
							</>
						) : (
							updating ? "update" : "save"
						)}
					</SaveBTN>
				</Grid>
				<TableWrapper component={Paper}>
					<Table>
						<TableHead style={{ backgroundColor: '#DDDDDD' }}>
							<TableRow>
								<TableHeadings>#</TableHeadings>
								<TableHeadings>Heading</TableHeadings>
								<TableHeadings>URL</TableHeadings>
								<TableHeadings>Action</TableHeadings>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableContent sx={{ width: "100%", }} >
										<MoonLoader color="#000" size={20} />
									</TableContent>
								</TableRow>
							) : (
								links.length === 0 ? (
									<TableRow align="center">
										<TableContent colSpan={4} align="center">
											You have not set any links yet!
										</TableContent>
									</TableRow>
								) : (
									links?.map((link, index) => (
										<TableRow key={index}>
											<TableContent sx={{ width: "1%" }} >{index + 1}</TableContent>
											<TableContent sx={{ width: "30%" }}>{link.eng_heading} , {link.arb_heading}</TableContent>
											<TableContent sx={{ width: "30%" }}>{link.link}</TableContent>
											<TableContent sx={{ display: "flex", gap: "10px", cursor: "pointer" }}>
												<Tooltip title="Edit" placement='top' >
													<EditIcon sx={{ fontSize: "20px", color: "#000" }} onClick={() => editLink(link)} />
												</Tooltip>
												<Tooltip title="Delete" placement='top' >
													<DeleteIcon sx={{ fontSize: "20px", color: "red" }} onClick={() => handleDelete(link.uuid)} />
												</Tooltip>
											</TableContent>
										</TableRow>
									))
								)
							)
							}
						</TableBody>
					</Table>
				</TableWrapper>
			</SectionWrapper>
		</>
	)
}

const SectionWrapper = styled(Box)(() => ({
	width: "100%",
	background: "#fff",
	padding: "10px 15px",
	borderRadius: "12px",
	fontSize: "22px",
	fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
	fontSize: "16px",
	fontWeight: "600",
	color: "#424242"
}))

const Input = styled(TextField)(({
	width: "100%",
	background: "#fff",
	borderRadius: "5px",
}))

const Label = styled(Typography)(() => ({
	fontSize: "12px",
	fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
	width: '120px',
	height: '40px',
	marginTop: "15px",
	fontSize: "16px",
	background: `${theme.palette.primary.main}`,
	color: "#fff",
	borderRadius: '5px',
	textTransform: "capitalize",
	"&:hover": {
		backgroundColor: `${theme.palette.primary.main}`
	}
}))

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none",
	margin: "30px 0"
}))

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "black",
	background: "#F2F4F5"
}))

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D",
	border: 'none'
}))

export default Links;

