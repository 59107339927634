import { ThemeProvider } from '@mui/material';
import RootComponent from './Router';
import theme from './constants/theme';
import { UserThemeProvider } from './contexts/ThemeContext';
import { LoaderProvider } from './contexts/LoaderContext';
import { ToastContainer } from 'react-toastify';

function App() {

    return (
        <UserThemeProvider>
            <LoaderProvider>
                <ThemeProvider theme={theme}>
                    <ToastContainer hideProgressBar={true} autoClose={2000} />
                    <RootComponent />
                </ThemeProvider>
            </LoaderProvider>
        </UserThemeProvider>
    );
}

export default App;
