import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Container, Grid, Box, Typography, FormControl, Select, MenuItem } from '@mui/material'

// Recharts Components Import
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

// Components Import
import ChartPlaceholder from '../components/ChartPlaceholder';

function SalesForecastDetails() {

    const [salesData, setSalesData] = useState(null)
    const [productName, setProductName] = useState('relogios_presentes');

    const fetchSalesData = async (productName) => {
        await axios.post('https://ai.3ndey.com/api/sales',
            {
                secret_key: 'ADKHJKLIYOUYTOI+kllkoqrbcnmx+8923570235236',
                product_name: productName,
            }
        )
        .then((response) => {
            setSalesData(response.data.p90)
        })
        .catch((err) => {
            console.error('Error fetching demand data:', err);
        })
    }

    useEffect(() => {
        fetchSalesData(productName);
    }, [productName]);

    return (
        <>
            <Wrapper maxWidth={false}>
                <Grid container my={3} sx={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "30px"}}>
                    <Typography sx={{ fontWeight: "500", fontSize: '14px' }} >Choose a Product:</Typography>
                    <Grid my={1} item md={3} sx={{ display: 'flex', flexDirection: 'column', bottom: '80px' }} >
                        <FormControl size="small" sx={{ mt: '5px', backgroundColor: '#fff', borderRadius: '5px' }}>
                            <Select
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            >
                                <MenuItem value={"relogios_presentes"}>Test Product 1</MenuItem>
                                <MenuItem value={"moveis_decoracao"}>Test Product 2</MenuItem>
                                <MenuItem value={"telefonia"}>Test Product 3</MenuItem>
                                <MenuItem value={"beleza_saude"}>Test Product 4</MenuItem>
                                <MenuItem value={"esporte_lazer"}>Test Product 5</MenuItem>
                                <MenuItem value={"bebes"}>Test Product 6</MenuItem>
                                <MenuItem value={"perfumaria"}>Test Product 7</MenuItem>
                                <MenuItem value={"cama_mesa_banho"}>Test Product 8</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container my={3} sx={{display: "flex", justifyContent: "space-between", gap: "30px"}}>
                    <Card item md={12}>
                        <CardHeading>Sales Forecasting</CardHeading>
                        {salesData? 
                            <Box sx={{height:"220px"}}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={salesData}>
                                        <XAxis dataKey="name" tick={{fontSize: "10px"}} />
                                        <YAxis tick={{fontSize: "10px"}} />
                                        <Line dot={false} strokeWidth={2}  type="monotone" dataKey="value" stroke="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>                        
                            </Box>
                            :
                            <ChartPlaceholder />
                        }
                    </Card>
                </Grid>
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "1rem",
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}));

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "15px 25px",
    borderRadius: "14px",
    border: "1px solid #ddd"
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600"
}))

export default SalesForecastDetails