import React, { useState } from 'react'
import { styled } from "@mui/material/styles";

// MUI Components import
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, FormControl, Select, MenuItem} from "@mui/material";

// Assets Import
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

function StaffList() {

    const [staffList, setStaffList] = useState([1])

    return (
        <>
            <TableWrapper component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#DDDDDD' }}>
                        <TableRow>
                            <TableHeadings>Name</TableHeadings>
                            <TableHeadings>Email</TableHeadings>
                            <TableHeadings>Phone</TableHeadings>
                            <TableHeadings>Role</TableHeadings>
                            <TableHeadings>Action</TableHeadings>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staffList.length === 0 ? (
                            <TableRow align="center">
                                <TableContent colSpan={4} align="center">
                                    You have not assigned any roles yet
                                </TableContent>
                            </TableRow>
                        ) : (
                            staffList.map((staff, index) => (
                            <TableRow key={index}>
                                <TableContent>Ghazi Muaz</TableContent>
                                <TableContent>muazwaseem88@gmail.com</TableContent>
                                <TableContent>+923152547742</TableContent>
                                <TableContent>Admin</TableContent>
                                <TableContent sx={{display: "flex", alignItems: "center", gap: "12px"}}>
                                    <FormControl fullWidth size="small" sx={{width: "80%", borderRadius: '5px' }}>
                                        <InputLabel id="role-label">Change role</InputLabel>
                                        <Select labelId="role-label" label="Change role">
                                            <MenuItem value={"staff"}>Staff</MenuItem>
                                            <MenuItem value={"manager"}>Manager</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <DeleteIcon />
                                </TableContent>
                            </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableWrapper>
        </>
    )
}

// Styled Components

const TableWrapper = styled(TableContainer)(() => ({
    height: "auto",
    overflow: "auto",
    border: "none",
    boxShadow: "none",
    margin: "30px 0"
}))

const TableHeadings = styled(TableCell)(() => ({
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "black",
    background: "#F2F4F5"
}))

const TableContent = styled(TableCell)(() => ({
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#71747D",
    border: 'none'
}))

export default StaffList