import { apiSlice } from "./apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ limit, offset }) => {
        return {
          url: `vendor/orders?limit=${limit}&offset=${offset}`,
          method: "GET",
        };
      },
      providesTags: ["Orders"],
    }),

    deliveryDateUpdate: builder.mutation({
      query: ({ orderId, body }) => {
        return {
          url: `order/delivery_date/${orderId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Orders"],
    }),

    staffNoteUpdate: builder.mutation({
      query: ({ orderId, body }) => {
        return {
          url: `order/staff_note/${orderId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useDeliveryDateUpdateMutation,
  useStaffNoteUpdateMutation,
} = ordersApiSlice;
