import {
  parsePhoneNumberFromString,
  parsePhoneNumber,
} from "libphonenumber-js";

// thousand separator
export const thousandSeparator = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// prevent auto form submission
export function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

// validate Mobile Number with country code
export const phoneNumberValidator = (value, countryCode) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(value, countryCode);
    return phoneNumber.isValid();
  } catch (error) {
    return false;
  }
};

// Masking Mobile Number
export const maskingPhoneNumber = (value) => {
  if (value) {
    const phoneNumber = parsePhoneNumber(`+${value}`);
    return phoneNumber.formatNational();
  }
};

// console.log(truncateString("Hello, world!", 5)); // Output: "Hello..."
export function truncateString(str, num) {
  if (str) return str.length > num ? `${str.substring(0, num)}...` : str;
}
