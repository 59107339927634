import AddIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Chip, Pagination, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../components/MUITable";
import {
  useDeleteServiceMutation,
  useGetServicesQuery,
} from "../../redux/api/serviceApiSlice";
// Icons Import
import EditIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
// React Toastify
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import PrimaryInput from "../../components/PrimaryInput/PrimaryInput";
import SelectInput from "../../components/SelectInput";
import Loader from "../../pages/Loaders/Loader";
import { useGetChargeTypesQuery } from "../../redux/api/chargeTypeApiSlice";
import { useGetServiceCategoriesQuery } from "../../redux/api/serviceCategoryApiSlice";
import { useGetServiceTypesQuery } from "../../redux/api/serviceTypeApiSlice";
import { thousandSeparator, truncateString } from "../../utils";
import ProductList from "../../pages/Dashboards/InventoryDash/pages/ProductList";

const tableHead = [
  "Service Name",
  "Category",
  "Service Type",
  "Description",
  "Service Terms",
  "Charge Type",
  "Charges",
  "Actions",
];

const Services = () => {
  // const navigate = useNavigate();
  // const rowsPerPage = 5;

  // const [deleteValue, setDeleteValue] = useState("");
  // const [page, setPage] = useState(1);
  // const [services, setServices] = useState([]);
  // // Filter State
  // const [searchText, setSearchText] = useState("");
  // const [category, setCategory] = useState("");
  // const [serviceType, setServiceType] = useState("");
  // const [filters, setFilters] = useState({
  //   category: "",
  //   serviceType: "",
  // });

  // // todo: 1) GET SERVICES API CALL
  // const { data: getServices, isLoading: getServicesLoading } =
  //   useGetServicesQuery({
  //     limit: rowsPerPage,
  //     offset: page,
  //     searchTerm: searchText,
  //     category: filters.category,
  //     serviceType: filters.serviceType,
  //   });

  // useEffect(() => {
  //   if (getServices) {
  //     setServices(getServices);
  //   }
  // }, [getServices]);

  // // todo: GET SERVICE TYPE API CALL
  // const { data: getServiceTypes, isLoading: getServiceTypesLoading } =
  //   useGetServiceTypesQuery({});

  // // todo: GET CHARGE TYPE API CALL
  // const { data: getChargeTypes, isLoading: getChargeTypeLoading } =
  //   useGetChargeTypesQuery({});

  // // todo: GET CATEGORY  API CALL
  // const { data: getCategories, isLoading: getCategoryLoading } =
  //   useGetServiceCategoriesQuery({});

  // // todo: 2) DELETE SERVICE API BIND
  // const [deleteService, { isLoading: deleteServiceLoading }] =
  //   useDeleteServiceMutation();

  // const handleDelete = async (id) => {
  //   try {
  //     const delService = await deleteService(id);

  //     if (!delService?.error) {
  //       toast.success("Service deleted successfully!");
  //     }
  //     if (delService?.error) {
  //       toast.error("Something went wrong", "error");
  //     }
  //   } catch (error) {
  //     console.error("Delete Service Error:", error);
  //     toast.error(error.response.data.message);
  //   }
  // };

  // const getCategoryName = (id) => {
  //   const name = getCategories?.find((item) => item.id === id);
  //   return name;
  // };

  // const getServiceTypeName = (typeArray) => {
  //   const filteredServiceType = getServiceTypes?.filter((type) =>
  //     typeArray?.includes(type.id)
  //   );

  //   return filteredServiceType;
  // };

  // const getChargeType = (id) => {
  //   const name = getChargeTypes?.find((item) => item.id === id);
  //   return name;
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const applyHandler = () => {
  //   setFilters({
  //     ...filters,
  //     category: category,
  //     serviceType: serviceType,
  //   });
  // };

  return (
    <>
      <ProductList serviceScreen={true} />

      {/* {(getServiceTypesLoading ||
        getChargeTypeLoading ||
        getCategoryLoading) && <Loader />} */}
      {/* <Box
        sx={{
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Services List</h3>

          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="secondary"
            backgroundColor={"green"}
            startIcon={<AddIcon />}
            onClick={() => navigate("/dashboard/add-service")}
          >
            Add Service
          </Button>
        </Box>
        <Box
          sx={{
            margin: "20px 0",
            padding: "20px",
            background: "#fff",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <PrimaryInput
                startAdornment={<SearchIcon />}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search Service by Name"
                sx={{
                  width: "300px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <SelectInput
                name="category"
                styles={{ width: "300px" }}
                value={category || ""}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                data={getCategories}
                fetching={getCategoryLoading}
                label="Categories"
                options={getCategories?.map((category) => {
                  return {
                    ...category,
                    id: category.id,
                    value: category.id,
                    label: `${category.name} - ${category.arb_name}`,
                  };
                })}
              ></SelectInput>

              <SelectInput
                name="serviceType"
                styles={{ width: "300px" }}
                value={serviceType || ""}
                onChange={(e) => {
                  setServiceType(e.target.value);
                }}
                data={getServiceTypes}
                fetching={getServiceTypesLoading}
                label="Service Type"
                options={getServiceTypes?.map((category) => {
                  return {
                    ...category,
                    id: category.id,
                    value: category.id,
                    label: `${category.en_service_name} - ${category.ar_service_name}`,
                  };
                })}
              ></SelectInput>

              <Button onClick={applyHandler}>Apply</Button>
              <Button
                onClick={() => {
                  setFilters({
                    category: "",
                    serviceType: "",
                  });
                  setSearchText("");
                  setCategory("");
                  setServiceType("");
                }}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            margin: "10px 0",
          }}
        >
          <MUITable tableHead={tableHead}>
            {getServicesLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "70px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <MoonLoader color="#000" size={20} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : services?.results?.length > 0 ? (
              services?.results?.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      {truncateString(item?.en_serviceName, 30)}
                      <br />
                      {truncateString(item?.ar_serviceName, 30)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${getCategoryName(item?.category)?.name} - ${
                        getCategoryName(item?.category)?.arb_name
                      }`}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {getServiceTypeName(item?.serviceType)?.map(
                          (service, index) => {
                            return (
                              <Chip
                                key={index}
                                label={`${service?.en_service_name}`}
                                sx={{
                                  fontSize: "12px",
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell>
                      {truncateString(item?.en_description, 30)}
                      <br />
                      {truncateString(item?.ar_description, 30)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {truncateString(item?.en_serviceTerms, 30)}
                      <br />
                      {truncateString(item?.ar_serviceTerms, 30)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${getChargeType(item?.chargeType)?.en_charge_name} - ${
                        getChargeType(item?.chargeType)?.ar_charge_name
                      }`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {thousandSeparator(Number(item?.rate))}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <EditIcon
                            sx={{ fontSize: "20px", color: "#07bc0c" }}
                            onClick={() => {
                              navigate(`/dashboard/edit-service/${item.uuid}`);
                            }}
                          />
                        </Tooltip>
                        {deleteServiceLoading &&
                        item.uuid === deleteValue.uuid ? (
                          <MoonLoader color="#000" size={16} />
                        ) : (
                          <Tooltip title="Delete" placement="top">
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "red" }}
                              onClick={() => {
                                handleDelete(item.uuid);
                                setDeleteValue(item);
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {services?.results?.length === 0 ? "No records found" : ""}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              justifyContent: "end",
              margin: "1px 0 0",
              padding: "15px 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#49464f",
                }}
              >
                Total Records: {services?.total_count}
              </Box>
              <Pagination
                count={Math.ceil(services?.total_count / rowsPerPage)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Box> */}
    </>
  );
};

export default Services;
