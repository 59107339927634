import React, { useState } from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container, Box, Button } from '@mui/material';

// Components Import
import Header from '../components/Header';
import StaffList from './components/StaffList';
import AddStaffModal from './components/AddStaffModal';
import AddRoleModal from './components/AddRoleModal';

// Assets Import
import AddIcon from "@mui/icons-material/AddOutlined";

const headerData = {
    heading: "Manage your Staff",
    subheading: "Add staff and manage their roles",
}

function Dashboard() {

    const [staffModalOpen, setStaffModalOpen] = useState(false);
    const [roleModalOpen, setRoleModalOpen] = useState(false);

    const handleCloseStaffModal = () => {
        setStaffModalOpen(false);
    };

    const handleCloseRoleModal = () => {
        setRoleModalOpen(false);
    };


    return (
        <>
            <Wrapper maxWidth={false}>
            <AddStaffModal open={staffModalOpen} onClose={handleCloseStaffModal} />
            <AddRoleModal open={roleModalOpen} onClose={handleCloseRoleModal} />
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Header data={headerData} />
                <Box sx={{display: "flex", alignItems: "center", gap: "15px"}}>
                    <Button variant="contained" color="secondary" startIcon={<AddIcon />}
                        onClick={() => setStaffModalOpen(true)}
                    >
                        Add Staff
                    </Button>
                    <Button variant="contained" color="secondary" startIcon={<AddIcon />}
                        onClick={() => setRoleModalOpen(true)}
                    >
                        Add Role
                    </Button>
                </Box>
            </Box>
            <StaffList />
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: '1rem',
    height: "calc(100vh - 60px)",
    overflow: "auto"
}))

export default Dashboard