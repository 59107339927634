import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
// MUI Components Import
import { Box, Grid, Typography, Button } from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";

function InvoiceLogo() {

    const token = window.localStorage.getItem("user-token");

    const logoUpload = useRef();

    const [saving, setSaving] = useState(false)
    const [logo, setLogo] = useState(null);
    const [previewImage, setPreviewImg] = useState(null);

    const selectLogo = () => {
        logoUpload.current.click();
    };

    const handleLogoUplaod = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            setPreviewImg(URL.createObjectURL(file));
        }
    };

    const removeImage = () => {
        setLogo(null);
        setPreviewImg(null);
    };

    const handleUpload = () => {
        if (!logo) {
            toast.error("Please upload an image.");
            return;
        }
        setSaving(true);

        const formData = new FormData();

        if (typeof logo !== "string") {
            formData.append("invoice_logo", logo);
        }

        axios.put(`${baseUrl}auth/settings`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            toast.success("Settings Updated");
            setSaving(false);
        })
        .catch((err) => {
            toast.error("Couldn't update the settings!");
            setSaving(false);
            console.log(err);
        });
    };

    const fetchLogo = async () => {
        try {
            const response = await axios.get(`${baseUrl}auth/settings`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            setLogo(response?.data?.invoice_logo);
            setPreviewImg(response?.data?.invoice_logo);
        } catch (error) {
            // handleLoader(false)
        }
    };

    useEffect(() => {
        fetchLogo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper mt={3}>
                <Heading>Upload logo for Invoice</Heading>
                <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"}>
                    <Uploader item md={2} onClick={() => selectLogo()}>
                        <input
                            type="file"
                            accept="image/*"
                            ref={logoUpload}
                            style={{ display: "none" }}
                            onChange={(e) => handleLogoUplaod(e)}
                            id="logoInput"
                        />
                        <AddPhoto sx={{ fontSize: "25px" }} />
                        <Typography
                            sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
                        >
                            Add Image
                        </Typography>
                    </Uploader>
                    <Grid item md={9}>
                        {logo ? (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <ImgDisplay
                                    sx={{
                                        background: `url(${previewImage}) center/cover no-repeat`,
                                    }}
                                />
                                <CancelIcon
                                    sx={{
                                        fontSize: "20px",
                                        position: "relative",
                                        right: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => removeImage()}
                                />
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item md={12}>
                        <SaveBTN onClick={() => handleUpload()}>
                            {saving ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Update"
                            )}
                        </SaveBTN>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242",
}));

const Uploader = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90px",
    cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
    width: "100px",
    height: "90px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
    borderRadius: "10px",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
    width: "120px",
    height: "40px",
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: "5px",
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
    },
}));

export default InvoiceLogo