import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
// MUI Components Import
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CodeBlock, atomOneLight } from "react-code-blocks";
import { baseUrl } from "../../../../../constants/api";

function GoogleScript() {
  const token = window.localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [googleKey, setGoogleKey] = useState("");
  const [isActive, setIsActive] = useState(false);

  const handleUpdate = async () => {
    if (!googleKey) {
      toast.error("Google Script key is required!");
      return;
    }
    setSaving(true);
    try {
      await axios.put(
        `${baseUrl}auth/settings`,
        {
          google_analytics: googleKey,
          google_analytics_active: isActive || false,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      toast.success("Google Script updated successfully!");
      setTimeout(() => {
        setSaving(false);
      }, 2000);
      fetchGoogleKey();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
      setSaving(false);
    }
  };

  const fetchGoogleKey = async () => {
    try {
      const response = await axios.get(`${baseUrl}auth/settings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      setGoogleKey(response?.data?.google_analytics || "");
      setIsActive(response?.data?.google_analytics_active || "");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGoogleKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const script = `<script async src="https://www.googletagmanager.com/gtag/js?id=${googleKey}">
  </script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', '${googleKey}');
  </script>`;

  return (
    <>
      <Wrapper mt={3}>
        <Heading>Google Script</Heading>

        <Grid container mt={1} spacing={3} display={"flex"}>
          <Grid item md={4}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Label>Add Google Script Key</Label>
              <Input
                placeholder="G-PNFLJZPS"
                value={googleKey}
                onChange={(e) => setGoogleKey(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box>
              <Label>Active</Label>
              <Checkbox
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <CodeBlock
                text={script}
                theme={atomOneLight}
                highlight="1-1,9-9"
                customStyle={{
                  height: "260px",
                  borderRadius: "5px",
                  boxShadow: "1px 2px 3px rgba(0,0,0,0.35)",
                  fontSize: "12px",
                  margin: "0px 0.75rem",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid
            item
            md={12}
            sx={{
              margin: "20px 0",
            }}
          >
            <SaveBTN onClick={() => handleUpdate()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Update"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  float: "right",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

export default GoogleScript;
