import { apiSlice } from "./apiSlice";

export const wareHouseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createWareHouse: builder.mutation({
      query: (body) => {
        return {
          url: "warehouse/create",
          method: "POST",
          body,
        };
      },
      providesTags: ["WareHouse"],
    }),

    updateWareHouse: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `warehouse/update/${id}`,
          method: "PUT",
          body,
        };
      },
      providesTags: ["WareHouse"],
    }),

    deleteWareHouse: builder.mutation({
      query: (id) => {
        return {
          url: `warehouse/delete/${id}`,
          method: "DELETE",
        };
      },
      providesTags: ["WareHouse"],
    }),

    getWareHousesByProduct: builder.query({
      query: ({ id, stock }) => {
        return {
          url: `product/stocks/${id}/${stock}`,
          method: "GET",
        };
      },
      providesTags: ["WareHouse"],
    }),

    postProductRequestFromWareHouse: builder.mutation({
      query: ({ body }) => {
        return {
          url: `warehouse/request/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["WareHouse"],
    }),

    getProductsByWareHouse: builder.query({
      query: ({ id }) => {
        return {
          url: `warehouse/request/${id}`,
          method: "GET",
        };
      },
      providesTags: ["WareHouse"],
    }),

    getAllRequestedProducts: builder.query({
      query: ({ status, limit, offset }) => {
        const url = status
          ? `warehouse/requests?status=${status}&limit=${limit}&offset=${offset}`
          : `warehouse/requests?limit=${limit}&offset=${offset}`;
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["WareHouse"],
    }),

    wareHouseProductStatusChange: builder.mutation({
      query: ({ id, status }) => {
        return {
          url: `warehouse/request/update/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["WareHouse"],
    }),

    deleteProductRequest: builder.mutation({
      query: (id) => {
        return {
          url: `warehouse/request/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["WareHouse"],
    }),
  }),
});

export const {
  useGetProductsByWareHouseQuery,
  usePostProductRequestFromWareHouseMutation,
  useCreateWareHouseMutation,
  useUpdateWareHouseMutation,
  useDeleteWareHouseMutation,
  useGetWareHousesByProductQuery,
  useGetAllRequestedProductsQuery,
  useWareHouseProductStatusChangeMutation,
  useDeleteProductRequestMutation,
} = wareHouseApiSlice;
