import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Modal, Box, Grid, Typography, TextField, Button, FormControl, Select, MenuItem, Autocomplete } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assets Import
import CloseIcon from '@mui/icons-material/ClearOutlined';
import { baseUrl } from '../../../../constants/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'background.paper',
    boxShadow: 24,
    borderRadius:"6px",
    gap: "12px",
    zIndex: "999"
};

function AddRoleModal({ open, onClose }) {

    const token = window.localStorage.getItem("user-token");

    const [name, setName] = useState('')
    const [permissions, setPermissions] = useState([])

    const fetchPermissions = () => {
        axios.get(`${baseUrl}permissions`, 
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            console.log("Permissions", response.data)
            setPermissions(response.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchPermissions()
    }, [])

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Header>
                        <Heading>Add New Role</Heading>
                        <CloseIcon sx={{cursor: "pointer"}} onClick={onClose} />
                    </Header>
                    <Wrapper>
                        <Grid container spacing={3} display={"flex"} alignItems={"center"} >
                            <Grid item md={12}>
                                <Label>Role Name</Label>
                                <Input value={name} onChange={(e) => setName(e.target.value)} />
                            </Grid>
                            <Grid item md={12}>
                                <Label>Set Permissions</Label>
                                {/* <Autocomplete multiple size="small" limitTags={2}
                                    sx={{width: "100%", height: "40px", border: "1px solid black", marginTop: "5px", borderRadius: "5px", backgroundColor: "#fff"}}
                                    options={permissions.map((role) => ({
                                        label: role.name, 
                                        value: role.id, 
                                    }))}                            
                                    // value={tags} 
                                    onChange={(_, newPermissions) => {
                                        setPermissions(newPermissions);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                
                                /> */}
                            </Grid>
                            {/* <Button mt={3} color='primary' sx={{cursor: "pointer"}} onClick={() => setTagsModalOpen(true)} >
                                Add more Tags
                            </Button> */}
                            <Grid item md={12}>
                                <SaveBTN>Add</SaveBTN>
                            </Grid>
                        </Grid>
                    </Wrapper>
                </Box>
            </Modal>
        </>
    )
}

const Header = styled(Box)(() => ({
    width: "auto",
    height: "auto",
    padding: "15px",
    background: "#dddddd",
    borderRadius: "6px 6px 0 0",
    borderBottom: "!px solid #FFC107",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600"
}))

const Wrapper = styled(Box)(() => ({
    width: "100%",
    height: "auto",
    padding: "32px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px"
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px', 
    height: '40px', 
    fontSize: "16px",
    float: "right",
    marginTop: '10px', 
    background: `${theme.palette.primary.main}`, 
    color: "#fff", 
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default AddRoleModal