import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
// MUI Components Import
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Checkbox,
} from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";

function TaxSelect() {
    const token = window.localStorage.getItem("user-token");

    const [saving, setSaving] = useState(false);
    const [tax, setTax] = useState();
    const [isActive, setIsActive] = useState(false);

    const handleUpdate = async () => {
        if (tax.length === 0) {
            toast.error("Please enter a valid discount value!");
            return;
        }

        if (tax <= 0 || tax > 100) {
            toast.error("Please enter a valid discount value! 1 to 100");
            return;
        }

        setSaving(true);

        try {
            await axios.put(
                `${baseUrl}auth/settings`,
                {
                    vat_tax: tax,
                    vat_tax_active: isActive,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            );

            toast.success("Tax value updated successfully!");
            setTimeout(() => {
                setSaving(false);
            }, 2000);
            fetchTax();
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message);
            }
            setSaving(false);
        }
    };

    const fetchTax = async () => {
        try {
            const response = await axios.get(`${baseUrl}auth/settings`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            setTax(response?.data?.vat_tax);
            setIsActive(response?.data?.vat_tax_active);
        } catch (error) {
            // handleLoader(false)
        }
    };

    const handleBlur = () => {
        // If the input value is not empty and does not contain a decimal point, add ".00" at the end
        if (tax && !/\./.test(tax)) {
            setTax(tax + ".00");
        }
    };

    useEffect(() => {
        fetchTax();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper mt={3}>
                <Heading>Set a Tax Value</Heading>
                <Grid
                    container
                    mt={1}
                    spacing={3}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item md={4}>
                        <Label>Vat %</Label>
                        <Input
                            placeholder="%"
                            value={tax}
                            onBlur={handleBlur}
                            onChange={(e) => setTax(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Label>Active</Label>
                        <Checkbox
                            type="checkbox"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <SaveBTN onClick={() => handleUpdate()}>
                            {saving ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Update"
                            )}
                        </SaveBTN>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    );
}

const Wrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600",
}));

const Input = styled(TextField)({
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
    width: "120px",
    height: "40px",
    marginTop: "15px",
    fontSize: "16px",
    float: "right",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: "5px",
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
    },
}));

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242",
}));

export default TaxSelect;
