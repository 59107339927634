import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from '@mui/material'

// Components Import
import Dashboard from './Dashboard';
import Navbar from '../Dashboards/components/Navbar';

function Profile() {
    return (
        <>
            <Wrapper maxWidth={false}>
                <Navbar />
                <Dashboard />
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)(() => ({
    padding: "0 !important",
	width: "100%",
	height: "auto",
    overflow: "auto"
}))

export default Profile