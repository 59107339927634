import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Box, Typography} from '@mui/material'

function ChartPlaceholder() {
    return (
        <>
            <StyledWrapper>
                <Error>No data to show</Error>
            </StyledWrapper>
        </>
    )
}

// Styled Components

const StyledWrapper = styled(Box)(() => ({
    height: "220px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const Error = styled(Typography)(() => ({
    fontWeight: "600",
    color: "#b7b0b0"
}))

export default ChartPlaceholder