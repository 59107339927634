import React, { useEffect, useState } from "react";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { useGetAllRequestedProductsQuery } from "../../../../redux/api/wareHouseApiSlice";
import Loader from "../../../Loaders/Loader";
import { Box, Pagination } from "@mui/material";

const tableHead = ["#", "Product Name", "Request From", "Request To"];

const renderInfoBox = (label, value) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      marginBottom: "5px",
    }}
  >
    <Box
      sx={{
        fontWeight: "bold",
        width: "100px",
      }}
    >
      {label}:
    </Box>
    <Box>{value}</Box>
  </Box>
);

const RejectedRequest = ({ status }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const RowsPerPage = 5;

  // todo: GET ALL PRODUCTS FROM (WAREHOUSES) API CALL
  const { data: productsData, isLoading: productsDataLoading } =
    useGetAllRequestedProductsQuery({
      status,
      limit: RowsPerPage,
      offset: page,
    });

  useEffect(() => {
    if (productsData) {
      setCount(productsData?.total_count);
    }
  }, [productsData]);

  // todo: PAGINATION
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <MUITable tableHead={tableHead}>
        {productsDataLoading ? (
          <Loader />
        ) : productsData?.results?.length === 0 ? (
          <StyledTableRow>
            <StyledTableCell
              sx={{ height: "100px" }}
              colSpan={tableHead?.length}
              align="center"
            >
              <Box
                sx={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {productsData?.results?.length === 0 ? "No records found" : ""}
              </Box>
            </StyledTableCell>
          </StyledTableRow>
        ) : productsData?.results?.length > 0 ? (
          productsData?.results?.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Box
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {index + 1}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      alt="product_image"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "5px",
                        marginRight: "8px",
                        objectFit: "fill",
                      }}
                    >
                      <img
                        src={item?.product?.images[0]?.image || ""}
                        alt="Product"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      {item?.product?.commons?.en?.productName}
                      <br />
                      {item?.product?.commons?.ar?.productName}
                    </Box>
                  </Box>
                </StyledTableCell>

                <StyledTableCell>
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      width: "80%",
                    }}
                  >
                    {renderInfoBox(
                      "Location",
                      `${item?.requested_from?.warehouse?.name} / ${item?.requested_from?.warehouse?.location}`
                    )}
                    {renderInfoBox(
                      "Batch Number",
                      item?.requested_from?.batchNumber
                    )}

                    {renderInfoBox(
                      "AVL Quantity",
                      Number(item?.requested_from?.productQuantity)
                    )}
                  </Box>
                </StyledTableCell>

                <StyledTableCell>
                  {item?.requested_items?.map(
                    (requestedItem, requestedIndex) => {
                      return (
                        <Box
                          key={requestedIndex}
                          sx={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            width: "80%",
                          }}
                        >
                          {renderInfoBox(
                            "Location",
                            `${requestedItem?.stock?.warehouse?.name} / ${requestedItem?.stock?.warehouse?.location}`
                          )}

                          {renderInfoBox(
                            "Batch Number",
                            item?.requested_from?.batchNumber
                          )}
                          {renderInfoBox(
                            "AVL Quantity",
                            Number(requestedItem?.stock?.productQuantity)
                          )}
                        </Box>
                      );
                    }
                  )}
                </StyledTableCell>
              </StyledTableRow>
            );
          })
        ) : (
          ""
        )}
      </MUITable>
      <Box
        sx={{
          background: "#fff",
          display: "flex",
          justifyContent: "end",
          margin: "1px 0 0",
          padding: "20px 0 0 0",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ fontSize: "14px", marginRight: "10px" }}>
            Total Records: {count}
          </Box>
        </Box>
        <Pagination
          count={Math.ceil(count / RowsPerPage)}
          shape="rounded"
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export default RejectedRequest;
