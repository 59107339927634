import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// MUI Components Import
import {
  Box,
  Modal,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TextField,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";

// Loader Import
import { MoonLoader } from "react-spinners";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";

// Datepicker Library Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";
import { useGetEntitiesQuery } from "../../../../../redux/api/categoryApiSlice";
import SelectInput from "../../../../../components/SelectInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "950px",
  minHeight: "320px",
  backgroundColor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  outline: "none",
  "@media (max-width: 576px)": {
    width: "90%",
  },
};

function StockModal({ open, setOpen, prodId, fetchProductList }) {
  const token = window.localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [stockProdQty, setStockProdQty] = useState();
  const [stockBatchNo, setStockBatchNo] = useState();
  const [stockExpiryDate, setStockExpiryDate] = useState(null);
  const [stockId, setStockId] = useState("");
  const [selectWareHouse, setSelectWareHouse] = useState("");
  const [wareHouseList, setWareHouseList] = useState([]);

  if (stockExpiryDate && typeof stockExpiryDate !== "string") {
    var date = dayjs(stockExpiryDate).format("YYYY-MM-DD");
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/,/g, "");

    // Allow clearing the input field entirely
    if (inputValue === "" || /^\d{0,10}(\.\d{0,2})?$/.test(inputValue)) {
      // Format the input value with commas
      const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setStockProdQty(formattedValue); // Call the onChange function from props to update the state
    }
  };

  const handleBlur = () => {
    // If the input value is not empty and does not contain a decimal point, add ".00" at the end
    if (stockProdQty && !/\./.test(stockProdQty)) {
      setStockProdQty(stockProdQty + ".00");
    }
  };

  const fetchStockData = (prodId) => {
    setLoading(true);
    axios
      .get(`${baseUrl}product/stock/${prodId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setStockData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateStockData = () => {
    if (
      !stockProdQty ||
      !stockBatchNo ||
      !stockExpiryDate ||
      !selectWareHouse
    ) {
      if (!stockProdQty) {
        toast.error("Product Quantity is required!");
      }
      if (!stockBatchNo) {
        toast.error("Batch Number is required!");
      }
      if (!stockExpiryDate) {
        toast.error("Expiry Date is required!");
      }
      if (!selectWareHouse) {
        toast.error("Warehouse is required!");
      }
      return;
    }

    setSaving(true);
    let response;
    if (!updating) {
      response = axios.post(
        `${baseUrl}product/stock/${prodId}`,
        {
          expiryDate: date,
          productQuantity: stockProdQty,
          batchNumber: stockBatchNo,
          warehouse: selectWareHouse?.uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
    } else {
      response = axios.put(
        `${baseUrl}product/stock/edit/${stockId}`,
        {
          expiryDate: date,
          productQuantity: stockProdQty,
          batchNumber: stockBatchNo,
          warehouse: selectWareHouse?.uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
    }
    response
      .then(() => {
        toast.success("Stock data updated!");
        setSaving(false);
        setUpdating(false);
        setStockProdQty();
        setStockBatchNo();
        setSelectWareHouse();
        setStockExpiryDate(null);
        setOpen(false);
        fetchProductList();
        fetchStockData(prodId);
      })
      .catch(() => {
        toast.error("Failed to update!");
        setSaving(false);
      });
  };

  // todo: GET ENTITIES API CALL
  const { data, isLoading: wareHouseFetching } = useGetEntitiesQuery({});

  useEffect(() => {
    if (data) {
      // only add which have is_active & set_as_ecommerce_warehouse
      const filteredData = data?.warehouses?.filter((item) => {
        return item.is_active && item.set_as_ecommerce_warehouse;
      });
      setWareHouseList(filteredData);
    }
  }, [data]);

  const editStock = (stock) => {
    const service = data?.warehouses?.find(
      (ser) => ser.id === stock?.warehouse?.id
    );

    setUpdating(true);
    setStockProdQty(stock.productQuantity);
    setStockBatchNo(stock.batchNumber);
    setSelectWareHouse(service);
    setStockExpiryDate(stock.expiryDate);
    setStockId(stock.uuid);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}product/stock/edit/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success("Batch deleted successfully!");
        fetchStockData(prodId);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchStockData(prodId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodId]);

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item md={3} my={2}>
              <Label>Batch Number</Label>
              <Input
                mt={3}
                placeholder="A01"
                type="text"
                size="small"
                color="success"
                variant="outlined"
                InputProps={{ sx: { borderRadius: "4px" } }}
                value={stockBatchNo}
                onChange={(e) => setStockBatchNo(e.target.value)}
              />
            </Grid>
            <Grid item md={3} my={2}>
              <Label>Product Quantity</Label>
              <Input
                mt={3}
                placeholder="1,000"
                type="text"
                size="small"
                color="success"
                variant="outlined"
                InputProps={{ sx: { borderRadius: "4px" } }}
                value={stockProdQty}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item md={3} my={2}>
              <Box
                sx={{
                  marginBottom: "5px",
                }}
              >
                <Label>Select Warehouse</Label>
              </Box>

              <SelectInput
                name="service"
                styles={{ width: "100%" }}
                borderRadius="4px"
                height="41px"
                value={selectWareHouse}
                onChange={(e) => {
                  setSelectWareHouse(e.target.value);
                }}
                data={wareHouseList}
                fetching={wareHouseFetching}
                label="Select Warehouse"
                options={wareHouseList?.map((ser) => {
                  return {
                    ...ser,
                    id: ser.id,
                    value: ser,
                    label: `${ser.name} / ${ser.location}`,
                  };
                })}
              ></SelectInput>
            </Grid>
            <Grid item md={3} my={2}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Expiry Date
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{
                  width: "100%",
                }}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  value={dayjs(stockExpiryDate)}
                  onChange={(value) => setStockExpiryDate(value)}
                  slotProps={{
                    textField: { size: "small", error: false },
                  }}
                  sx={{ background: "#fff" }}
                  minDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              md={12}
              my={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ textTransform: "none", width: "120px" }}
                onClick={() => updateStockData()}
              >
                {saving ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : updating ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadings>Batch Number</TableHeadings>
                <TableHeadings>Product Quantity</TableHeadings>
                <TableHeadings>Warehouse Location</TableHeadings>
                <TableHeadings>Expiry Date</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableRow>
                <TableContent colSpan={12} sx={{ width: "100%" }}>
                  <MoonLoader color="#000" size={20} />
                </TableContent>
              </TableRow>
            ) : stockData.length === 0 ? (
              <TableRow align="center">
                <TableContent colSpan={12} align="center">
                  This product has no stock information
                </TableContent>
              </TableRow>
            ) : (
              <TableBody>
                {stockData.map((stock, index) => {
                  return (
                    <TableRow key={index}>
                      <TableContent>{stock?.batchNumber}</TableContent>
                      <TableContent>{stock?.productQuantity}</TableContent>
                      <TableContent>
                        {stock?.warehouse?.name} / {stock?.warehouse?.location}
                      </TableContent>

                      <TableContent>{stock?.expiryDate}</TableContent>
                      <TableContent>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            color="primary"
                            onClick={() => editStock(stock)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                          <IconButton
                            color="primary"
                            onClick={() => handleDelete(stock.uuid)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableContent>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Box>
      </Modal>
    </div>
  );
}

// Styled Components

const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
});

const Input = styled(TextField)({
  marginTop: "5px",
  background: "#fff",
  borderRadius: "12px",
  width: "100%",
});

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#2E2E2E",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#71747D",
}));

export default StockModal;
