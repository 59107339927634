import { apiSlice } from "./apiSlice";

export const chargeTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargeTypes: builder.query({
      query: () => {
        return {
          url: "charge-types",
          method: "GET",
        };
      },
      providesTags: ["ChargeTypes"],
    }),

    createChargeType: builder.mutation({
      query: (body) => {
        return {
          url: "charge-type/create",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["ChargeTypes"],
    }),

    updateChargeType: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `charge-type/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ChargeTypes"],
    }),

    deleteChargeType: builder.mutation({
      query: (id) => {
        return {
          url: `charge-type/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ChargeTypes"],
    }),
  }),
});

export const {
  useGetChargeTypesQuery,
  useCreateChargeTypeMutation,
  useUpdateChargeTypeMutation,
  useDeleteChargeTypeMutation,
} = chargeTypeApiSlice;
