import { apiSlice } from "./apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: ({ limit, offset }) => {
        return {
          url: `customers?limit=${limit}&offset=${offset}`,
          method: "GET",
        };
      },
      providesTags: ["Customer"],
    }),

    getCustomerOrders: builder.query({
      query: ({ customerId, limit, offset, startDate, endDate, ordId }) => {
        const date = startDate && endDate;

        const url = date
          ? `orders/${customerId}?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&ordId=${ordId}`
          : `orders/${customerId}?limit=${limit}&offset=${offset}&ordId=${ordId}`;

        return {
          url,
        };
      },
      providesTags: ["Customer"],
    }),
  }),
});

export const { useGetCustomerQuery, useGetCustomerOrdersQuery } =
  customerApiSlice;
