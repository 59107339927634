import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import 
import { Box, Typography } from '@mui/material'

const Header = ({ data }) => {
    return (
        <Box>
            <Heading>{data.heading}</Heading>
            <SubHeading mt={1}>{data.subheading}</SubHeading>
        </Box>
    )
}

// Styled Components

const Heading = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "700",
}))

const SubHeading = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "400",
    color: "#9FA1A6"
}))

export default Header