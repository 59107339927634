import { apiSlice } from "./apiSlice";

export const utilApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSaudiCity: builder.query({
      query: () => {
        return {
          url: "cities/sa",
          method: "GET",
        };
      },
    }),

    hotSellingProducts: builder.query({
      query: () => {
        return {
          url: "bestsellers",
          method: "GET",
        };
      },
    }),

    bulkProductsDelete: builder.mutation({
      query: (products) => {
        return {
          url: "products/delete-multiple",
          method: "POST",
          body: products,
        };
      },
    }),

    getThemeSettings: builder.query({
      query: () => {
        return {
          url: "auth/settings",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetSaudiCityQuery,
  useHotSellingProductsQuery,
  useBulkProductsDeleteMutation,
  useGetThemeSettingsQuery,
} = utilApiSlice;
