import React, { useState } from "react";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import {
  useGetAllRequestedProductsQuery,
  useWareHouseProductStatusChangeMutation,
} from "../../../../redux/api/wareHouseApiSlice";
import Loader from "../../../Loaders/Loader";
import { Box, Button, Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

const tableHead = ["#", "Product Name", "Request From", "Request To", "Status"];

const renderInfoBox = (label, value) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      marginBottom: "5px",
    }}
  >
    <Box
      sx={{
        fontWeight: "bold",
        width: "100px",
      }}
    >
      {label}:
    </Box>
    <Box>{value}</Box>
  </Box>
);

const SentRequest = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  // todo: GET ALL PRODUCTS FROM (WAREHOUSES) API CALL
  const { data: productsData, isLoading: productsDataLoading } =
    useGetAllRequestedProductsQuery({});

  const [
    updateWareHouseProductStatus,
    { isLoading: updateWareHouseProductStatusLoading },
  ] = useWareHouseProductStatusChangeMutation();

  const statusChangeHandler = async (status, id) => {
    try {
      const update = await updateWareHouseProductStatus({
        status,
        id,
      });
      if (!update?.error) {
        toast.success("Your feed back send to the vendor!");
      }
      if (update?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Warehouse Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      You have no sent requests!
      {/* <MUITable tableHead={tableHead}>
        {productsDataLoading ? (
          <Loader />
        ) : productsData?.results?.length > 0 ? (
          productsData?.results?.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Box
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {index + 1}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      alt="product_image"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "5px",
                        marginRight: "8px",
                        objectFit: "fill",
                      }}
                    >
                      <img
                        src={item?.product?.images[0]?.image || ""}
                        alt="Product"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      {item?.product?.commons?.en?.productName}
                      <br />
                      {item?.product?.commons?.ar?.productName}
                    </Box>
                  </Box>
                </StyledTableCell>

                <StyledTableCell>
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      width: "80%",
                    }}
                  >
                    {renderInfoBox(
                      "Location",
                      `${item?.requested_from?.warehouse?.name} / ${item?.requested_from?.warehouse?.location}`
                    )}
                    {renderInfoBox(
                      "Batch Number",
                      item?.requested_from?.batchNumber
                    )}

                    {renderInfoBox(
                      "AVL Quantity",
                      Number(item?.requested_from?.productQuantity)
                    )}
                  </Box>
                </StyledTableCell>

                <StyledTableCell>
                  {item?.requested_items?.map(
                    (requestedItem, requestedIndex) => {
                      return (
                        <Box
                          key={requestedIndex}
                          sx={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            width: "80%",
                          }}
                        >
                          {renderInfoBox(
                            "Location",
                            `${requestedItem?.stock?.warehouse?.name} / ${requestedItem?.stock?.warehouse?.location}`
                          )}
                          {renderInfoBox(
                            "Req Quantity",
                            Number(requestedItem?.requestedQuantity)
                          )}
                          {renderInfoBox(
                            "AVL Quantity",
                            Number(requestedItem?.stock?.productQuantity)
                          )}
                        </Box>
                      );
                    }
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.status === 3 ? (
                    <Typography
                      sx={{
                        color: "#f44336",
                        fontSize: "14px",
                        fontWeight: "bold",
                        backgroundColor: "#ffebee",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        width: "fit-content",
                      }}
                    >
                      The request has been Rejected!
                    </Typography>
                  ) : item?.status === 2 ? (
                    <Typography
                      sx={{
                        color: "#4caf50",
                        fontSize: "14px",
                        fontWeight: "bold",
                        backgroundColor: "#e8f5e9",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        width: "fit-content",
                      }}
                    >
                      Request has been approved!
                    </Typography>
                  ) : item?.status === 4 ? (
                    <Box>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "5px",
                          backgroundColor: "#4caf50",
                          color: "#fff",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#4caf50",
                          },
                        }}
                        onClick={() => {
                          statusChangeHandler(5, item?.uuid);
                          setSelectedItem(item);
                        }}
                      >
                        {updateWareHouseProductStatusLoading &&
                        selectedItem?.uuid === item.uuid ? (
                          <BeatLoader color="#fff" size={10} />
                        ) : (
                          "Received"
                        )}
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </StyledTableCell>
              </StyledTableRow>
            );
          })
        ) : (
          ""
        )}
      </MUITable> */}
    </>
  );
};

export default SentRequest;
