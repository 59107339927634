import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box, Button, Container, TextField, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@mui/material/styles/styled";
import Header from "../../components/Header";
import Loader from "../../../Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { thousandSeparator } from "../../../../utils/index";
import CustomChip from "../../../../components/CustomChip";
import { useGetCustomerOrdersQuery } from "../../../../redux/api/customerAPISlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";

const tableHead = [
  "#",
  "Order ID",
  "Date",
  "Amount",
  "Order Status",
  "Payment Status",
];

const headerData = {
  heading: "Customer Orders",
  subheading: "",
};

const CustomerOrderList = () => {
  const { profId } = useParams();
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dates, setDates] = useState({
    startDate: skipToken,
    endDate: skipToken,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState(skipToken);

  const searchParam = searchText !== skipToken ? searchText : "";
  const startDateParam = dates.startDate !== skipToken ? dates.startDate : "";
  const endDateParam = dates.endDate !== skipToken ? dates.endDate : "";

  const { data, isLoading, isSuccess } = useGetCustomerOrdersQuery({
    limit: 10,
    offset: page,
    customerId: profId,
    startDate: startDateParam,
    endDate: endDateParam,
    ordId: searchParam,
  });

  useEffect(() => {
    if (data) {
      setOrders(data.results);
      setCount(data.total_count);
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleApplyFilters = () => {
    if (!endDate || !startDate) {
      toast.error("Please select both start and end date");
    }

    setDates({
      startDate: startDate,
      endDate: endDate,
    });
  };

  const handleSearch = () => {
    setSearchText(searchTerm);
  };

  const handleResetFilters = () => {
    setStartDate("");
    setEndDate("");
    setSearchTerm("");
    setDates({
      startDate: skipToken,
      endDate: skipToken,
    });
    setSearchText(skipToken);
  };

  return (
    <Wrapper maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Header data={headerData} />
      </Box>

      <SearchNav>
        <Box
          sx={{
            width: "30vw",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "#bdbdbd" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by Order ID"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Search>
          <Button variant="text" onClick={handleSearch}>
            Search
          </Button>
        </Box>
        <Box
          sx={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            sx={{ width: "150px" }}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            sx={{ width: "150px" }}
          />
          <Button variant="text" onClick={handleApplyFilters}>
            Apply
          </Button>
          <Button variant="text" onClick={handleResetFilters}>
            Reset
          </Button>
        </Box>
      </SearchNav>

      <Box>
        {isLoading && <Loader />}
        <Box
          sx={{
            margin: "10px 0",
            boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
          }}
        >
          <MUITable tableHead={tableHead}>
            {isSuccess && orders?.length > 0 ? (
              orders?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{row?.ordId}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(row?.created_at).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {thousandSeparator(Number(row?.total_price).toFixed(2))}
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomChip label={row?.status_display} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomChip label={row?.payment_status} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell
                  sx={{ height: "100px" }}
                  colSpan={tableHead?.length}
                  align="center"
                >
                  <Box
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    {orders?.length === 0 ? "No records found" : ""}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </MUITable>
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              justifyContent: "end",
              margin: "1px 0 0",
              padding: "15px 10px",
            }}
          >
            <Pagination
              count={Math.ceil(count / 10)}
              shape="rounded"
              onChange={handleChangePage}
            />
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled("input")(({ theme }) => ({
  color: "inherit",
  paddingLeft: "40px",
  height: "100%",
  width: "100%",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
}));

export default CustomerOrderList;
