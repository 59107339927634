import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import styled from "styled-components";
import { Box, Button, Container, Pagination, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { RiInboxArchiveLine } from "react-icons/ri";
import {
  useDeleteProductRequestMutation,
  useGetAllRequestedProductsQuery,
  useWareHouseProductStatusChangeMutation,
} from "../../../../redux/api/wareHouseApiSlice";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import Loader from "../../../Loaders/Loader";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { MdDeleteOutline } from "react-icons/md";
import SentRequest from "./SentRequest";
import RejectedRequest from "./RejectedRequest";
import { FcCancel } from "react-icons/fc";
import SelectInput from "../../../../components/SelectInput";
import CustomChip from "../../../../components/CustomChip";
import { SiTicktick } from "react-icons/si";
import { CiDeliveryTruck } from "react-icons/ci";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none", // Change text capitalization
  fontSize: "15px",
  "& svg.MuiTab-iconWrapper": {
    fontSize: "20px !important",
  },
}));

const headerData = {
  heading: "Product Requests from the Warehouse",
  subheading: "",
};

const tableHead = [
  "#",
  "Product Name",
  "Request From",
  "Request To",
  "Status",
  "Actions",
];

const StatusData = [
  {
    id: 1,
    name: "Accept",
    value: 2,
  },
  {
    id: 2,
    name: "Reject",
    value: 3,
  },
  {
    id: 3,
    name: "Shipped",
    value: 4,
  },
];

const renderInfoBox = (label, value) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      marginBottom: "5px",
    }}
  >
    <Box
      sx={{
        fontWeight: "bold",
        width: "100px",
      }}
    >
      {label}:
    </Box>
    <Box>{value}</Box>
  </Box>
);

// todo: START COMPONENT
const RequestProductWareHouse = () => {
  const [value, setValue] = useState(0);
  const [statusName, setStatusName] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const RowsPerPage = 5;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // todo: GET ALL PRODUCTS FROM (WAREHOUSES) API CALL
  const { data: productsData, isLoading: productsDataLoading } =
    useGetAllRequestedProductsQuery({
      status: filterStatus,
      limit: RowsPerPage,
      offset: page,
    });

  useEffect(() => {
    if (productsData) {
      setCount(productsData?.total_count);
    }
  }, [productsData]);

  // todo: UPDATE WAREHOUSE API BIND
  const [
    updateWareHouseProductStatus,
    { isLoading: updateWareHouseProductStatusLoading },
  ] = useWareHouseProductStatusChangeMutation();

  const statusChangeHandler = async (status, id) => {
    try {
      const update = await updateWareHouseProductStatus({
        status,
        id,
      });

      const message =
        status === 2 ? "accept" : status === 3 ? "reject" : "deliver";

      if (!update?.error) {
        toast.success(`You have successfully ${message} the product request!`);
      }
      if (update?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Warehouse Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // todo: DELETE WAREHOUSE API BIND
  // const [
  //   deleteWareHouseProductStatus,
  //   { isLoading: deleteWareHouseProductStatusLoading },
  // ] = useDeleteProductRequestMutation();

  // const deleteProductHandler = async (id) => {
  //   try {
  //     const update = await deleteWareHouseProductStatus(id);

  //     if (!update?.error) {
  //       toast.success("Your product request has been successfully deleted!");
  //     }
  //     if (update?.error) {
  //       toast.error("Something went wrong", "error");
  //     }
  //   } catch (error) {
  //     console.error("Warehouse Type Error:", error);
  //     toast.error(error.response.data.message);
  //   }
  // };

  // todo: PAGINATION
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />

        <Box
          sx={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            marginTop: "1rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
              >
                <CustomTab
                  label="Received Requests"
                  {...a11yProps(0)}
                  icon={<RiInboxArchiveLine />}
                  iconPosition="start"
                />
                <CustomTab
                  label="Sent Requests"
                  {...a11yProps(1)}
                  icon={<RiInboxUnarchiveLine />}
                  iconPosition="start"
                />
                <CustomTab
                  label="Accepted"
                  {...a11yProps(2)}
                  icon={<SiTicktick />}
                  iconPosition="start"
                />
                <CustomTab
                  label="Rejected"
                  {...a11yProps(3)}
                  icon={<FcCancel />}
                  iconPosition="start"
                />
                <CustomTab
                  label="Shipped"
                  {...a11yProps(4)}
                  icon={<CiDeliveryTruck />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box
                sx={{
                  margin: "20px 0 4px 0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <SelectInput
                    name="service"
                    styles={{ width: "150px" }}
                    value={filterStatus}
                    borderRadius="5px"
                    onChange={(e) => {
                      setFilterStatus(e.target.value);
                    }}
                    data={StatusData}
                    label="Select Status"
                    options={StatusData?.map((ser) => {
                      return {
                        ...ser,
                        id: ser.id,
                        value: ser.value,
                        label: ser.name,
                      };
                    })}
                  ></SelectInput>
                </Box>
                <MUITable tableHead={tableHead}>
                  {productsDataLoading ? (
                    <Loader />
                  ) : productsData?.results?.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{ height: "100px" }}
                        colSpan={tableHead?.length}
                        align="center"
                      >
                        <Box
                          sx={{
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          {productsData?.results?.length === 0
                            ? "No records found"
                            : ""}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : productsData?.results?.length > 0 ? (
                    productsData?.results?.map((item, index) => {
                      // console.log("item", item);
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            <Box
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              {index + 1}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                alt="product_image"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  marginRight: "8px",
                                  objectFit: "fill",
                                }}
                              >
                                <img
                                  src={item?.product?.images[0]?.image || ""}
                                  alt="Product"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {item?.product?.commons?.en?.productName}
                                <br />
                                {item?.product?.commons?.ar?.productName}
                              </Box>
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Box
                              sx={{
                                border: "1px solid #ddd",
                                padding: "10px",
                                borderRadius: "5px",
                                marginBottom: "10px",
                                // width: "80%",
                              }}
                            >
                              {renderInfoBox(
                                "Location",
                                `${item?.requested_from?.warehouse?.name} / ${item?.requested_from?.warehouse?.location}`
                              )}
                              {renderInfoBox(
                                "Batch Number",
                                item?.requested_from?.batchNumber
                              )}
                              {renderInfoBox(
                                "AVL Quantity",
                                Number(item?.requested_from?.productQuantity)
                              )}
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell>
                            {item?.requested_items?.map(
                              (requestedItem, requestedIndex) => {
                                return (
                                  <Box
                                    key={requestedIndex}
                                    sx={{
                                      border: "1px solid #ddd",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                      // width: "80%",
                                    }}
                                  >
                                    {renderInfoBox(
                                      "Location",
                                      `${requestedItem?.stock?.warehouse?.name} / ${requestedItem?.stock?.warehouse?.location}`
                                    )}
                                    {renderInfoBox(
                                      "Batch Number",
                                      requestedItem?.stock?.batchNumber
                                    )}
                                    {renderInfoBox(
                                      "Req Quantity",
                                      Number(requestedItem?.requestedQuantity)
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {<CustomChip label={item?.status_display} />}
                          </StyledTableCell>
                          <StyledTableCell>
                            {item?.status === 3 ? (
                              <Typography
                                sx={{
                                  color: "#f44336",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  backgroundColor: "#ffebee",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  width: "fit-content",
                                }}
                              >
                                The request has been Rejected!
                              </Typography>
                            ) : item?.status === 2 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  // alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#4caf50",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    backgroundColor: "#e8f5e9",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    width: "fit-content",
                                  }}
                                >
                                  Ready to be Shipped!
                                </Typography>
                                <Button
                                  sx={{
                                    padding: "5px 10px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#2196f3",
                                    width: "fit-content",
                                    color: "#fff",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#1976d2",
                                    },
                                  }}
                                  onClick={() => {
                                    statusChangeHandler(4, item.uuid);
                                    setStatusName("Deliver");
                                    setSelectedItem(item);
                                  }}
                                >
                                  {updateWareHouseProductStatusLoading &&
                                  statusName === "Deliver" &&
                                  selectedItem?.uuid === item.uuid ? (
                                    <BeatLoader color="#fff" size={10} />
                                  ) : (
                                    "Ship"
                                  )}
                                </Button>
                              </Box>
                            ) : item?.status === 4 ? (
                              <Typography
                                sx={{
                                  color: "#2196f3",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  backgroundColor: "#e3f2fd",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  width: "fit-content",
                                }}
                              >
                                Request has been shipped!
                              </Typography>
                            ) : item?.status === 5 ? (
                              ""
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  sx={{
                                    padding: "5px 10px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#4caf50",
                                    color: "#fff",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#388e3c",
                                    },
                                    "&:disabled": {
                                      backgroundColor: "#ddd",
                                    },
                                  }}
                                  onClick={() => {
                                    statusChangeHandler(2, item.uuid);
                                    setStatusName("Accept");
                                    setSelectedItem(item);
                                  }}
                                >
                                  {updateWareHouseProductStatusLoading &&
                                  statusName === "Accept" &&
                                  selectedItem?.uuid === item.uuid ? (
                                    <BeatLoader color="#fff" size={10} />
                                  ) : (
                                    "Accept"
                                  )}
                                </Button>
                                <Button
                                  sx={{
                                    padding: "5px 10px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#f44336",
                                    color: "#fff",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#d32f2f",
                                    },
                                    "&:disabled": {
                                      backgroundColor: "#ddd",
                                    },
                                  }}
                                  onClick={() => {
                                    statusChangeHandler(3, item.uuid);
                                    setStatusName("Reject");
                                    setSelectedItem(item);
                                  }}
                                >
                                  {updateWareHouseProductStatusLoading &&
                                  statusName === "Reject" &&
                                  selectedItem?.uuid === item.uuid ? (
                                    <BeatLoader color="#fff" size={10} />
                                  ) : (
                                    "Reject"
                                  )}
                                </Button>
                                {/* <Button
                                  sx={{
                                    padding: "5px 10px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#ff9800",
                                    color: "#fff",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#f57c00",
                                    },
                                  }}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    deleteProductHandler(item.uuid);
                                  }}
                                >
                                  {deleteWareHouseProductStatusLoading &&
                                  selectedItem?.uuid === item.uuid ? (
                                    <BeatLoader color="#fff" size={10} />
                                  ) : (
                                    "Cancel"
                                  )}
                                </Button> */}
                              </Box>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    ""
                  )}
                </MUITable>
                <Box
                  sx={{
                    background: "#fff",
                    display: "flex",
                    justifyContent: "end",
                    margin: "1px 0 0",
                    padding: "20px 0 0 0",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ fontSize: "14px", marginRight: "10px" }}>
                      Total Records: {count}
                    </Box>
                  </Box>
                  <Pagination
                    count={Math.ceil(count / RowsPerPage)}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box
                sx={{
                  margin: "20px 0 4px 0",
                }}
              >
                <SentRequest />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box
                sx={{
                  margin: "20px 0 4px 0",
                }}
              >
                <RejectedRequest status={2} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box
                sx={{
                  margin: "20px 0 4px 0",
                }}
              >
                <RejectedRequest status={3} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Box
                sx={{
                  margin: "20px 0 4px 0",
                }}
              >
                <RejectedRequest status={4} />
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default RequestProductWareHouse;
