import React from "react";
import { Typography } from "@mui/material";

const errors = {
  color: "#e74c3c",
  lineHeight: "20px",
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "inherit",
  paddingTop: "5px",
  margin: "0",
  paddingLeft: "14px",
};

const Errors = (props) => {
  const { sx } = props;
  return (
    <Typography component="p" sx={[errors, sx]}>
      {props.children}
    </Typography>
  );
};

export default Errors;
