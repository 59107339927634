import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserThemeContext } from "../../../contexts/ThemeContext";
import "../../styles.css";

// MUI Components Import
import { Box, Card, CardMedia } from "@mui/material";

// Pro Sidebar Components Import
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";

// MUI Icons Import
import InventoryIcon from "@mui/icons-material/DashboardOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import axios from "axios";
import { baseUrl } from "../../../constants/api";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { RiStackLine } from "react-icons/ri";
import { HiOutlineUsers } from "react-icons/hi2";
import { AiOutlineControl } from "react-icons/ai";

function SideBar() {
  const { navTheme } = useUserThemeContext();
  const colorRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const current_loc = location.pathname.split("/").pop();

  const token = window.localStorage.getItem("user-token");

  const [logoData, setLogoData] = useState([]);

  const fetchLogo = () => {
    axios
      .get(`${baseUrl}auth/settings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setLogoData(response.data.logo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLogo();
    colorRef.current.style.backgroundColor = navTheme;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navTheme]);

  const menuItemsOne = [
    { label: "Dashboard", path: "" },
    { label: "Products", path: "products-list" },
    { label: "Upload Products", path: "bulk" },
    { label: "Reviews", path: "reviews" },
  ];

  const menuItemsTwo = [
    {
      label: "Order Listing",
      path: "sales/orders-list",
      activePath: "orders-list",
    },
    {
      label: "Refunds Listing",
      path: "sales/refunds-list",
      activePath: "refunds-list",
    },
  ];

  const menuItemsThree = [
    {
      label: "Control",
      path: "warehouse/add-warehouse",
      activePath: "add-warehouse",
    },
    {
      label: "Products Request",
      path: "warehouse/products-request",
      activePath: "products-request",
    },
  ];

  const menuItemsFour = [
    {
      label: "Customers",
      path: "customers/customer-list",
      activePath: "customer-list",
    },
  ];

  const menuItemsFive = [
    {
      label: "Brands",
      path: "controls/brands",
      activePath: "brands",
    },
    {
      label: "Models",
      path: "controls/models",
      activePath: "models",
    },
    {
      label: "Categories",
      path: "controls/categories",
      activePath: "categories",
    },
    {
      label: "Subcategories",
      path: "controls/subcategories",
      activePath: "subcategories",
    },
    {
      label: "Units",
      path: "controls/units",
      activePath: "units",
    },
    {
      label: "Variants",
      path: "controls/variants",
      activePath: "variants",
    },
    {
      label: "Testimonials",
      path: "controls/testimonials",
      activePath: "testimonials",
    },
  ];

  const menuItemsSix = [
    {
      label: "Add Banners",
      path: "settings/banners",
      activePath: "banners",
    },
    {
      label: "Configurations",
      path: "settings/config",
      activePath: "config",
    },
    {
      label: "Theme",
      path: "settings/theme",
      activePath: "theme",
    },
    {
        label: "Partners",
        path: "settings/partners",
        activePath: "partners",
      },
    {
      label: "Footer",
      path: "settings/footer",
      activePath: "footer",
    },
  ];

  return (
    <>
      <SidebarWrapper
        ref={colorRef}
        sx={{ backgroundColor: `${navTheme}`, height: "100vh" }}
      >
        <LogoHolder>
          <Logo component="img" image={logoData} />
        </LogoHolder>
        <StyledSidebar>
          <Menu>
            <StyledSubMenu
              icon={<InventoryIcon />}
              label="Inventory Management"
            >
              {menuItemsOne.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={
                    current_loc === item.path ||
                    (current_loc === "dashboard" && item.path === "")
                  }
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>

            <StyledSubMenu icon={<RiStackLine />} label="Orders Management">
              {menuItemsTwo.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={current_loc === item.activePath}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>

            <StyledSubMenu
              icon={<MdOutlineMapsHomeWork />}
              label="Warehouse Management"
            >
              {menuItemsThree.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={current_loc === item.activePath}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>

            <StyledSubMenu icon={<HiOutlineUsers />} label="Customers">
              {menuItemsFour.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={current_loc === item.activePath}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>

            <StyledSubMenu icon={<AiOutlineControl />} label="Controls">
              {menuItemsFive.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={current_loc === item.activePath}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>

            <StyledSubMenu icon={<SettingsIcon />} label="Settings">
              {menuItemsSix.map((item) => (
                <StyledMenuItem
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  active={current_loc === item.activePath}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledSubMenu>
          </Menu>
        </StyledSidebar>
      </SidebarWrapper>
    </>
  );
}

// Styled Components

const SidebarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "300px",
  height: "auto",
  border: "none",
  transition: "all 300ms ease",
  overflowY: "auto",
  overflowX: "hidden",
}));

const LogoHolder = styled(Card)(() => ({
  width: "100%",
  height: "90px",
  boxShadow: "none",
  background: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 25px",
  marginTop: "20px",
}));

const Logo = styled(CardMedia)(() => ({
  width: "125px",
  height: "80px",
}));

const StyledSidebar = styled(Sidebar)(() => ({
  width: "100%",
  minWidth: "250px",
  marginTop: "20px",
  border: "none",
  color: "#fff",
  background: "none",
  borderRight: "unset !important",
}));

const StyledSubMenu = styled(SubMenu)(() => ({
  ".ps-menu-button": {
    borderRadius: "8px",
    height: "45px !important",
    padding: "20px !important",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ active }) => ({
  width: "unset !important",
  position: "relative",
  borderRadius: "8px",
  padding: "0",
  margin: "5px 20px",
  backgroundColor: active ? "#fff" : "none",
  color: active ? "#000" : "#fff",
  "&:hover": {
    backgroundColor: active ? "#fff" : "rgba(255, 255, 255, 0.1)",
  },
}));

export default SideBar;
